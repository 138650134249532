import { unitsConverter } from "@/config/units";
import { getDepth } from "@/utils/geoTiffHelpers";

export const pipelinesGetters = {
  selectedPipeline(state) {
    return state.groupPipelines.find(
      ({ id }) => id === state.selectedPipelineId
    );
  },

  convertedPipelineElevation(state) {
    if (state.pipelineElevation.length === 0) {
      return [];
    }

    return state.pipelineElevation.map((item) =>
      unitsConverter.instance.convert(item, "m")
    );
  },

  convertedPipelineDepth(_, getters) {
    if (getters.pipelineDepth.length === 0) {
      return [];
    }

    return getters.pipelineDepth.map((item) =>
      unitsConverter.instance.convert(item, "m")
    );
  },

  heightRange(_, getters) {
    const yAxisValues = [
      ...getters.convertedHeightProfileZ,
      ...getters.convertedPipelineElevation,
      ...getters.convertedPipelineDepth,
    ];

    const min = Math.min(...yAxisValues);
    const max = Math.max(...yAxisValues);

    return [min, max];
  },

  convertedHeightProfileDistance(state) {
    return state.selectedInspectionStats.height_profile.distance.map((item) =>
      unitsConverter.instance.convert(item, "m")
    );
  },

  convertedHeightProfileZ(state) {
    return state.selectedInspectionStats.height_profile.z.map((item) =>
      unitsConverter.instance.convert(item, "m")
    );
  },

  pipelineDepth(state) {
    const elevation = state.pipelineElevation;
    const inspectionStats = state.selectedInspectionStats;

    if (elevation.length === 0 || !inspectionStats) {
      return [];
    }

    return getDepth(elevation, inspectionStats.height_profile.z);
  },
};
