export const mapMutations = {
  setMapStyle(state, style) {
    state.mapStyle = style;
  },
  switchMapmode(state) {
    state.isMapMode = !state.isMapMode;
  },
  switchMapScanners(state) {
    state.showMapScanners = !state.showMapScanners;
  },
};
