<template>
  <div class="slider">
    <RangeSlider
      :title="$t('joints.filters.horizontal_angle')"
      :model-value="jointFilters.selectedHorizontalAngle"
      @update:modelValue="
        (value) => setFilters({ selectedHorizontalAngle: value })
      "
      :max="maxHorizontalAngle"
      :min="0"
      :step="0.01"
      lazy
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { RangeSlider } from "@/components";

export default {
  computed: {
    ...mapState("joints", ["jointFilters"]),
    ...mapGetters("joints", ["maxHorizontalAngle"]),
  },
  methods: {
    ...mapMutations("joints", ["setFilters"]),
  },
  components: {
    RangeSlider,
  },
};
</script>

<style scoped lang="scss">
.slider {
  width: 25%;
}
</style>
