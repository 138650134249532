import { authState } from "@/store/auth/state";
import { authMutations } from "@/store/auth/mutations";
import { authActions } from "@/store/auth/actions";

export const authModule = {
  namespaced: true,
  state: authState,
  mutations: authMutations,
  actions: authActions,
};
