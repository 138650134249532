<template>
  <div v-if="selectedPipeline" class="container">
    <v-card rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("anomalies.table_name") }}
        <i>
          {{ inspectionTitle }}
        </i>
      </v-card-title>
      <v-card-text :class="[isLoadingAnomalies ? '' : 'pt-6px']">
        <div v-if="isLoadingAnomalies" class="text-center">
          <v-progress-circular
            color="dark-blue"
            indeterminate
            :size="52"
            :width="5"
          ></v-progress-circular>
        </div>
        <div v-else>
          <div class="filters-containers">
            <AnomaliesTypesSelector hide-details />
            <AnomaliesMaterialsSelector hide-details />
            <AnomaliesDistanceRangeSlider />
            <AnomaliesSelectedLengthSlider />
          </div>
          <AnomaliesTable />
        </div>
      </v-card-text>
    </v-card>

    <v-card v-if="!isLoadingAnomalies" rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("anomalies.details.title") }}
      </v-card-title>
      <v-card-text>
        <p class="text-h6">{{ $t("anomalies.details.map") }}</p>
        <AnomalyMap
          :onSelectLegend="
            (value) => $emit('update:filterSelectedTypes', value)
          "
        />
        <p class="text-h6">
          {{ $t("anomalies.details.height_profile.title") }}
        </p>
        <div class="anomaly-charts">
          <AnomaliesHeightChart class="height-chart" />
          <AnomaliesCylinder />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  AnomalyMap,
  AnomaliesDistanceRangeSlider,
  AnomaliesSelectedLengthSlider,
  AnomaliesTypesSelector,
  AnomaliesMaterialsSelector,
} from "./components";
import {
  AnomaliesHeightChart,
  AnomaliesCylinder,
  AnomaliesTable,
} from "@/pages/anomaly/components";

export default {
  name: "PipelineAnomalies",
  components: {
    AnomaliesTable,
    AnomalyMap,
    AnomaliesHeightChart,
    AnomaliesCylinder,
    AnomaliesDistanceRangeSlider,
    AnomaliesSelectedLengthSlider,
    AnomaliesTypesSelector,
    AnomaliesMaterialsSelector,
  },
  emits: ["update:filterSelectedTypes", "update:filterSelectedMaterials"],

  computed: {
    ...mapState("pipelines", [
      "selectedInspectionStats",
      "selectedInspectionId",
    ]),
    ...mapGetters("pipelines", ["selectedPipeline"]),
    ...mapState("anomalies", ["isLoadingAnomalies", "anomalyFilters"]),
    ...mapGetters("anomalies", [
      "minMaxDistance",
      "minMaxLength",
      "anomaliesTypes",
      "filterMaterials",
    ]),

    inspectionTitle() {
      if (!this.selectedPipeline) return "";
      const inspectionName = this.selectedPipeline.inspections.find(
        (ins) => ins.id === this.selectedInspectionId
      )?.project_reference;
      return `- ${this.selectedPipeline.client_pipeline_id} : ${this.selectedPipeline.pipeline_name} - ${inspectionName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.pt-6px {
  padding-top: 6px !important;
}

.filters-containers {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
  .v-select {
    width: 25%;
    margin-inline: 0.6rem;
  }
}

.anomaly-charts {
  display: flex;
  flex-wrap: wrap;
  .height-chart {
    width: 800px;
  }
}
</style>
