<template>
  <div class="container">
    <v-card rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("pipe_parts.table_name") }}
        <i>
          {{ inspectionTitle }}
        </i>
      </v-card-title>
      <v-card-text :class="[isLoading ? '' : 'pt-6px']">
        <div v-if="isLoading" class="text-center">
          <v-progress-circular
            color="dark-blue"
            indeterminate
            :size="52"
            :width="5"
          ></v-progress-circular>
        </div>
        <div v-else>
          <div class="filters">
            <SafetyFactorMainContributorSelector
              :items="uncertaintiesOptions"
              hide-details
            />
            <SafetyFactorSlider class="slider" />
            <SafetyFactorLowerSlider class="slider" />
            <SafetyFactorUpperSlider class="slider" />
          </div>
          <SafetyTable />
        </div>
      </v-card-text>
    </v-card>

    <v-card v-if="!isLoading" rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("pipe_parts.details.title") }}
      </v-card-title>
      <v-card-text>
        <p class="text-h6">{{ $t("safety_factor.details.map") }}</p>
        <SafetyFactorMap
          :mapData="mapData"
          :safetyFactorOptions="safetyFactorOptions"
        />
        <p class="text-h6">
          {{ $t("safety_factor.widgets.height_profile") }}
        </p>
        <SafetyHeightChart />
        <p class="text-h6">{{ safetyChartTitle }}</p>
        <SafetyChart />
        <p class="text-h6">
          {{ $t("safety_factor.widgets.contributions_chart") }}
        </p>
        <UncertaintyContributionsChart />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  UncertaintyContributionsChart,
  SafetyHeightChart,
  SafetyChart,
  SafetyTable,
} from "@/pages/safety-factor/components";
import {
  SafetyFactorMap,
  SafetyFactorMainContributorSelector,
  SafetyFactorSlider,
  SafetyFactorLowerSlider,
  SafetyFactorUpperSlider,
} from "./components";

export default {
  name: "SafetyFactor",

  props: {
    mapData: Array,
    safetyFactorOptions: Array,
    uncertaintiesOptions: Array,
    safetyChartTitle: String,
  },

  components: {
    SafetyFactorMap,
    SafetyChart,
    SafetyTable,
    SafetyHeightChart,
    UncertaintyContributionsChart,
    SafetyFactorMainContributorSelector,
    SafetyFactorSlider,
    SafetyFactorLowerSlider,
    SafetyFactorUpperSlider,
  },

  computed: {
    ...mapState("pipelines", ["selectedInspectionId"]),

    ...mapGetters("pipelines", ["selectedPipeline"]),

    ...mapState("safetyFactor", ["isLoading"]),

    inspectionTitle() {
      const inspectionName = this.selectedPipeline.inspections.find(
        (ins) => ins.id === this.selectedInspectionId
      )?.project_reference;
      return `- ${this.selectedPipeline.client_pipeline_id} : ${this.selectedPipeline.pipeline_name} - ${inspectionName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.pt-6px {
  padding-top: 6px !important;
}

.filters {
  display: flex;
  text-align: center;
  align-items: flex-end;
  margin: 0.5rem 0 1rem;
  .slider,
  .v-select {
    width: 25%;
    padding: 0 0.25rem;
  }
}
</style>
