<template>
  <div class="tab-container">
    <div v-if="progressBcans.isLoading" class="loader">
      <v-progress-circular
        color="dark-blue"
        indeterminate
        :size="52"
        :width="5"
      ></v-progress-circular>
    </div>
    <template v-else>
      <v-row no-gutters>
        <v-col md="12" lg="4">
          <v-card :elevation="4">
            <h3>Bscan data:</h3>
            <v-select
              v-model="selectedFileName"
              :items="usedFilesOptions"
              label="File"
              variant="underlined"
              :disabled="isLoadingCalcPeaks"
            ></v-select>
          </v-card>

          <v-card :elevation="4">
            <h3>{{ $t("analyser.peak_detect.comment") }}</h3>
            <v-textarea
              v-model="commentsModel"
              variant="outlined"
              counter
              :placeholder="isLoadingComments ? 'loading...' : ''"
              :disabled="isLoadingComments || isSavingComments"
              :loading="isLoadingComments"
            ></v-textarea>
            <v-btn
              rounded="lg"
              color="deep-orange"
              type="submit"
              :disabled="isLoadingComments"
              :loading="isSavingComments"
              @click="saveComments"
            >
              {{ $t("analyser.peak_detect.save_comments") }}
            </v-btn>
          </v-card>

          <PeakDetectForm
            :selectedFile="selectedFile"
            :onSubmit="calculatePeaks"
            :isLoadingCalcPeaks="isLoadingCalcPeaks"
          />
        </v-col>

        <v-col md="12" lg="8">
          <v-card :elevation="4" class="plot-card">
            <div v-if="progressCorrelated.isLoading" class="progress-loader">
              {{ `${progressCorrelated.percent}%` }}
            </div>
            <template v-else>
              <PlotlyGraph
                v-if="bScans"
                :data="bscanCorrelationPlotData"
                :layout="{
                  yaxis: {
                    autorange: 'reversed',
                    range: [50, 250],
                  },
                  showlegend: true,
                }"
              />
              <PlotlyGraph
                :data="wtPlotData"
                :layout="{
                  showlegend: true,
                }"
                :display-mode-bar="true"
              ></PlotlyGraph>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getInspectionComments,
  postComments,
  postPeakDetect,
  getBscansCorrelated,
} from "@/features/analyser/api";

import { PlotlyGraph } from "@/components";
import PeakDetectForm from "./PeakDetectForm.vue";

export default {
  name: "PeakDetectTab",
  components: {
    PeakDetectForm,
    PlotlyGraph,
  },
  emits: ["step-complete"],
  props: {
    bScans: Object,
    progressBcans: Object,
    files: Array,
    usedFilesOptions: Array,
  },
  data() {
    return {
      selectedFileName: null,
      isLoadingCalcPeaks: false,
      isLoadingComments: false,
      isSavingComments: false,
      comments: null,
      peaksData: {},

      bscansCorrelation: null,
      progressCorrelated: {
        isLoading: true,
        percent: 0,
      },

      frontWall: [0, 0],
      backWall: [0, 0],
    };
  },

  mounted() {
    this.fetchBscansCorrelated();
    this.fetchComments();

    this.selectedFileName = this.usedFilesOptions[0];
  },

  computed: {
    ...mapState("auth", ["selectedGroup"]),

    inspectionId() {
      return this.$route.params.id;
    },

    selectedFile() {
      if (this.files.length === 0 || !this.selectedFileName) {
        return undefined;
      }
      return this.files.find(({ name }) => name === this.selectedFileName);
    },

    commentsModel: {
      get() {
        if (!this.comments || !this.selectedFileName) {
          return "";
        }
        return this.comments[this.selectedFileName];
      },
      set(value) {
        if (this.comments) {
          this.comments[this.selectedFileName] = value;
        }
      },
    },

    wtPlotData() {
      if (!this.peaksData[this.selectedFileName]) {
        return [];
      }
      return [
        {
          y: this.peaksData[this.selectedFileName].wt,
          mode: "markers",
          type: "scatter",
          name: "WT [mm]",
          marker: {
            color: "rgb(255, 0, 0)",
          },
        },
      ];
    },

    bscanCorrelationPlotData() {
      if (!this.bscansCorrelation) {
        return [];
      }

      let xMax = 0;
      if (this.bscansCorrelation && this.selectedFileName) {
        xMax = this.bscansCorrelation[this.selectedFileName][0].length;
      }

      const data = [
        {
          z: this.bscansCorrelation[this.selectedFileName],
          type: "heatmap",
          colorscale: "Jet",
          name: "B-SCAN",
        },
        {
          x: [0, xMax],
          y: [this.frontWall[0], this.frontWall[0]],
          mode: "lines",
          line_color: "indigo",
          type: "scatter",
          name: "FW-window-min",
        },
        {
          x: [0, xMax],
          y: [this.frontWall[1], this.frontWall[1]],
          mode: "lines",
          types: "scatter",
          line_color: "indigo",
          name: "FW-window-max",
        },
        {
          x: [0, xMax],
          y: [this.backWall[0], this.backWall[0]],
          mode: "lines",
          type: "scatter",
          line_color: "green",
          name: "BW-window-min",
        },
        {
          x: [0, xMax],
          y: [this.backWall[1], this.backWall[1]],
          mode: "lines",
          type: "scatter",
          line_color: "green",
          name: "BW-window-max",
        },
      ];

      if (this.peaksData[this.selectedFileName]) {
        data.push(
          {
            y: this.peaksData[this.selectedFileName].front_wall,
            mode: "markers",
            type: "scatter",
            name: "Front-wall",
            marker: {
              color: "rgb(255, 0, 0)",
            },
          },
          {
            y: this.peaksData[this.selectedFileName].back_wall,
            mode: "markers",
            type: "scatter",
            name: "Back-wall",
            marker: {
              color: "rgb(0, 255, 0)",
            },
          }
        );
      }

      return data;
    },
  },

  methods: {
    async fetchBscansCorrelated() {
      try {
        this.progressCorrelated.isLoading = true;

        const token = await this.$auth0.getAccessTokenSilently();
        this.bscansCorrelation = await getBscansCorrelated(
          token,
          this.inspectionId,
          this.selectedGroup,
          this.onDownloadProgress
        );
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Fetch bscans correlated - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.progressCorrelated.isLoading = false;
      }
    },

    async fetchComments() {
      try {
        this.isLoadingComments = true;
        const token = await this.$auth0.getAccessTokenSilently();
        this.comments = await getInspectionComments(
          token,
          this.inspectionId,
          this.selectedGroup
        );
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Fetch comments - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.isLoadingComments = false;
      }
    },

    async saveComments() {
      try {
        this.isSavingComments = true;
        const token = await this.$auth0.getAccessTokenSilently();
        await postComments(
          token,
          this.inspectionId,
          this.selectedGroup,
          this.comments
        );

        this.$toast.success("Comments saved", {
          position: "top-right",
        });
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Fetch comments - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.isSavingComments = false;
      }
    },

    async calculatePeaks(form) {
      try {
        this.isLoadingCalcPeaks = true;
        const token = await this.$auth0.getAccessTokenSilently();
        const data = await postPeakDetect(
          token,
          this.inspectionId,
          this.selectedGroup,
          form
        );
        this.peaksData[this.selectedFileName] = { ...data };
        this.checkIfAllFilesAreCalculated();
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Calculating peaks - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.isLoadingCalcPeaks = false;
      }
    },

    onDownloadProgress(progressEvent) {
      if (progressEvent) {
        this.progressCorrelated.percent = (
          progressEvent.progress * 100
        ).toFixed();
      }
    },

    checkIfAllFilesAreCalculated() {
      if (
        this.usedFilesOptions.every((fileName) =>
          this.peaksData.hasOwnProperty(fileName)
        )
      ) {
        this.$emit("step-complete");
      }
      const calculatedAmount = Object.getOwnPropertyNames(
        this.peaksData
      ).length;

      this.$toast.default(
        `Calculated ${calculatedAmount} of ${this.usedFilesOptions.length} files`,
        {
          position: "bottom",
        }
      );
    },
  },

  watch: {
    files() {
      this.fetchComments();
    },

    usedFilesOptions(options) {
      this.selectedFileName = options[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-container {
  .v-card {
    padding: 1.5rem 1.5rem 2rem;
    &:first-child {
      padding: 1rem 1.5rem 0.6rem;
    }
  }

  .plot-card {
    margin-top: 1.6rem;
  }

  @media (min-width: 1280px) {
    .plot-card {
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }

  .progress-loader {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;
    font-weight: bold;
    opacity: 0.7;
  }

  .loader {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

<style lang="scss">
.tab-container {
  h3 {
    margin-bottom: 0.6rem;
  }
}
</style>
