<template>
  <Transition>
    <v-stepper v-if="visible" non-linear multiple :model-value="[0, 1, 2]">
      <v-stepper-header>
        <v-stepper-item
          :value="0"
          :complete="!isLoading[ScannerType.Results]"
          :color="isLoading[ScannerType.Results] ? '#1c243d' : 'green'"
        >
          <template v-if="isLoading[ScannerType.Results]" v-slot:icon>
            <v-icon class="loader" icon="mdi-loading"></v-icon>
          </template>
          {{ $t(`pipe_scanner.types.${ScannerType.Results}`) }}
        </v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item
          :value="2"
          :complete="!isLoading[ScannerType.AnalysisPending]"
          :color="isLoading[ScannerType.AnalysisPending] ? '#1c243d' : 'green'"
        >
          <template v-if="isLoading[ScannerType.AnalysisPending]" v-slot:icon>
            <v-icon class="loader" icon="mdi-loading"></v-icon>
          </template>
          {{ $t(`pipe_scanner.types.${ScannerType.AnalysisPending}`) }}
        </v-stepper-item>

        <v-divider></v-divider>

        <v-stepper-item
          :value="1"
          :complete="!isLoading[ScannerType.InfoPending]"
          :color="isLoading[ScannerType.InfoPending] ? '#1c243d' : 'green'"
        >
          <template v-if="isLoading[ScannerType.InfoPending]" v-slot:icon>
            <v-icon class="loader" icon="mdi-loading"></v-icon>
          </template>
          {{ $t(`pipe_scanner.types.${ScannerType.InfoPending}`) }}
        </v-stepper-item>
      </v-stepper-header>
    </v-stepper>
  </Transition>
</template>

<script>
import { mapState } from "vuex";

import { ScannerType } from "@/pages/scanner-control/config";

export default {
  name: "ScannerLoaders",

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    return {
      ScannerType,
    };
  },

  computed: {
    ...mapState("pipeScanners", ["isLoading"]),
  },
};
</script>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.loader {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
