import jwt_decode from "jwt-decode";
import { DefaultService } from "@/open-api-code/ili-api";
import { getGeoTIFF } from "@/utils/geoTiffHelpers";
import { auth0 } from "@/config/auth0";
import { useToast } from "vue-toast-notification";
import { userCommentsAPI } from "@/features/comments/api";

const requests = new Map();
const toast = useToast();

export const pipelinesActions = {
  async getElevation({ commit }) {
    try {
      const controller = new AbortController();
      const stats = await requests.get("statsRequest");
      const elevation = await getGeoTIFF(
        stats.map_trace_geometry.coordinates,
        controller
      );

      commit("setPipelineElevation", elevation);
      requests.set("geoTIFFRequest", controller);
    } catch (error) {
      console.error(error);
    }
  },
  async getPipelines({ commit, rootState }) {
    commit("setIsLoadingPipelines", true);
    try {
      const token = await auth0.getAccessTokenSilently();
      if (token) {
        requests.set(
          "pipelinesRequest",
          DefaultService.readPipelinesInlineInspectionsPipelinesGroupGet(
            rootState.auth.selectedGroup
          )
        );
        const pipelines = await requests.get("pipelinesRequest");
        commit("setGroupPipelines", pipelines);
      } else {
        console.error("No token, try to log in!");
      }
    } catch (error) {
      if (error.name !== "CancelError") {
        console.error(error);
        toast.error(`Read Inspection Stats - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      commit("setIsLoadingPipelines", false);
    }
  },
  async getPipeComments({ commit, rootState }, pipelineId) {
    try {
      commit("setComments", []);

      const comments = await userCommentsAPI.getAll(
        pipelineId,
        rootState.auth.selectedGroup
      );

      commit("setComments", comments);
    } catch (error) {
      if (error.name !== "CanceledError") {
        console.error(error);
        toast.error(`Read user comments - ${error.message}`, {
          position: "top-right",
        });
      }
    }
  },
  async getInspectionStats({ commit, dispatch, rootState, state }) {
    commit("setIsLoadingStats", true);
    commit("setSelectedInspectionStats", null);
    try {
      const token = await auth0.getAccessTokenSilently();
      if (token) {
        const payload = jwt_decode(token);
        const groupKey = "https://acqgroups.cloud/groups";
        if (Object.prototype.hasOwnProperty.call(payload, groupKey)) {
          requests.set(
            "statsRequest",
            DefaultService.readInspectionStatsInlineInspectionsInspectionStatsInspectionIdGroupGet(
              state.selectedInspectionId,
              rootState.auth.selectedGroup
            )
          );
          const stats = await requests.get("statsRequest");

          commit("setSelectedInspectionStats", stats);
          await dispatch("getElevation");

          requests.delete("statsRequest");
          commit("setIsLoadingStats", false);
        }
      }
    } catch (error) {
      if (error.name !== "CancelError") {
        console.error(error);
        this.$toast.error(`Read Inspection Stats - ${error.message}`, {
          position: "top-right",
        });
        commit("setIsLoadingStats", false);
      }
    }
  },
  cancelRequests() {
    if (requests.has("statsRequest")) {
      requests.get("statsRequest").cancel();
    }
    if (requests.has("geoTIFFRequest")) {
      requests.get("geoTIFFRequest").abort();
    }
  },
};
