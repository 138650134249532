import { getMinMaxValue } from "@/utils/filterHelpers";
import { filterScanners } from "@/features/pipescanner/helpers/filterScanners";

import { ScannerType } from "@/pages/scanner-control/config";
import { unitsConverter } from "@/config/units";

export const scannerControlGetters = {
  convertedResultsScanners(_state, _getters, rootState) {
    const scanners = rootState.pipeScanners.pipeScanners[ScannerType.Results];

    if (rootState.settings.units === "metric") {
      return scanners;
    }

    const convertedScanners = scanners.map((scanner) => {
      ["diameter", "wallthickness_expected_validation"].forEach((key) => {
        scanner[key] = unitsConverter.instance.convert(scanner[key], "mm");
      });

      ["max", "mean", "min", "std"].forEach((key) => {
        scanner.stats[key] = unitsConverter.instance.convert(
          scanner.stats[key],
          "mm"
        );
      });

      return scanner;
    });

    return convertedScanners;
  },

  filteredResultsScanners(state, getters) {
    const filtered = filterScanners(
      getters.convertedResultsScanners,
      ScannerType.Results,
      state.filters
    );

    return filtered;
  },

  filteredInfoPendingScanners(state, _getters, rootState) {
    return filterScanners(
      rootState.pipeScanners.pipeScanners[ScannerType.InfoPending],
      ScannerType.InfoPending,
      state.filters
    );
  },

  filteredAnalysisPendingScanners(state, _getters, rootState) {
    return filterScanners(
      rootState.pipeScanners.pipeScanners[ScannerType.AnalysisPending],
      ScannerType.AnalysisPending,
      state.filters
    );
  },

  filteredPipeScanners(_, getters) {
    return {
      [ScannerType.Results]: getters.filteredResultsScanners,
      [ScannerType.InfoPending]: getters.filteredInfoPendingScanners,
      [ScannerType.AnalysisPending]: getters.filteredAnalysisPendingScanners,
    };
  },

  currentFilteredScanners(state, getters) {
    return getters.filteredPipeScanners[state.selectedScannerType];
  },

  activeScannerId(state) {
    return state.activeScannerIds[state.selectedScannerType];
  },

  activeScanner(state, getters, rootState) {
    return rootState.pipeScanners.pipeScanners[state.selectedScannerType].find(
      (scanner) => scanner.id === getters.activeScannerId
    );
  },

  minMaxDiameters(_, getters) {
    const [min, max] = getMinMaxValue(
      getters.convertedResultsScanners,
      "diameter"
    );

    return [Math.floor(min), Math.ceil(max)];
  },

  filteredResultsScannersIds(_state, getters) {
    return getters.filteredResultsScanners.map((scanner) => scanner.id);
  },

  hasFilteredScanners(state, getters, rootState) {
    const hasFilteredResultsScanners =
      getters.filteredResultsScanners.length !==
      rootState.pipeScanners.pipeScanners[ScannerType.Results].length;

    const hasFilteredInfoPendingScanners =
      getters.filteredInfoPendingScanners.length !==
      rootState.pipeScanners.pipeScanners[ScannerType.InfoPending].length;

    const hasFilteredAnalysisPendingScanners =
      getters.filteredAnalysisPendingScanners.length !==
      rootState.pipeScanners.pipeScanners[ScannerType.AnalysisPending].length;

    const filtered = {
      [ScannerType.Results]: hasFilteredResultsScanners,
      [ScannerType.InfoPending]: hasFilteredInfoPendingScanners,
      [ScannerType.AnalysisPending]: hasFilteredAnalysisPendingScanners,
    };

    return filtered[state.selectedScannerType];
  },

  selectedResultsScannersCount(state) {
    return state.selectedResultScannerIds.length;
  },

  isAllResultsScannersSelected(_, getters) {
    return (
      getters.filteredResultsScanners.length > 0 &&
      getters.selectedResultsScannersCount ===
        getters.filteredResultsScanners.length
    );
  },
};
