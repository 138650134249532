<template>
  <div class="container">
    <PipelinesTable
      :pipelines="pipelines"
      :onSelect="onSelectPipelineId"
      :selectedPipelineId="selectedPipelineId"
      :isLoading="isLoadingPipelines"
    />
    <PipelineOverview
      v-if="selectedPipelineId && !isLoadingPipelines"
      :key="selectedPipelineId"
      :isLoading="isLoadingStats"
    />
  </div>
</template>

<script>
import { PipelineOverview, PipelinesTable } from "./components";
import { mapState } from "vuex";

export default {
  name: "PipelinesDashboard",
  components: {
    PipelinesTable,
    PipelineOverview,
  },
  props: {
    pipelines: {
      type: Array,
      default() {
        return [];
      },
    },
    isLoadingPipelines: Boolean,
    isLoadingStats: Boolean,
    onSelectPipelineId: Function,
  },
  computed: {
    ...mapState("pipelines", ["selectedPipelineId"]),
  },
};
</script>
