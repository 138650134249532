<template>
  <div>
    <div v-if="editMode" class="input-section">
      <v-file-input
        v-model="files"
        label="File input"
        show-size
        accept="image/*"
        multiple
        variant="solo"
        density="compact"
        hide-details
        :loading="isSending"
      ></v-file-input>
      <v-btn
        @click="sendImageFiles"
        size="small"
        rounded="xl"
        :disabled="files.length === 0 || isSending"
        >{{ $t("pipe_scanner.images.add_img") }}</v-btn
      >
    </div>
    <v-card id="gallery-card">
      <v-card-title v-if="!editMode">{{
        $t("pipe_scanner.images.title")
      }}</v-card-title>
      <v-card-text>
        <div v-if="isLoading" class="blank">
          <v-progress-circular
            color="dark-blue"
            indeterminate
            :size="40"
            :width="5"
          ></v-progress-circular>
        </div>
        <div v-else-if="!images.length" class="blank">
          {{ $t("pipe_scanner.images.no_images") }}
        </div>
        <v-carousel
          v-else
          id="scanner-gallery"
          :hide-delimiters="images.length === 1"
          :show-arrows="images.length === 1 ? false : 'hover'"
          hide-delimiter-background
          style="height: 466px"
        >
          <template v-slot:prev="{ props }">
            <v-icon
              icon="mdi-arrow-left-drop-circle"
              color="white"
              @click="props.onClick"
            ></v-icon>
          </template>
          <template v-slot:next="{ props }">
            <v-icon
              icon="mdi-arrow-right-drop-circle"
              color="white"
              @click="props.onClick"
            ></v-icon>
          </template>
          <v-carousel-item v-for="{ src, width, height } in images" :key="src">
            <a
              :href="src"
              target="_blank"
              rel="noreferrer"
              :data-pswp-width="width"
              :data-pswp-height="height"
            >
              <v-img :src="src" style="background-color: white"></v-img>
            </a>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  getScannerPhotos,
  postScannerPhotos,
} from "@/features/pipescanner/api";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

export default {
  name: "ScannerGallery",
  props: {
    scannerId: { type: String, required: true },
    editMode: Boolean,
  },
  data() {
    return {
      files: [],
      isSending: false,
      images: [],
      isLoading: true,
      lightbox: null,
      controllers: new Map(),
    };
  },
  mounted() {
    this.fetchScannerPhoto();
  },
  unmounted() {
    this.removeGallery();
    if (this.controllers.has("photosRequest")) {
      this.controllers.get("photosRequest").abort();
    }
  },

  computed: {
    ...mapState("auth", ["selectedGroup"]),
  },

  methods: {
    async fetchScannerPhoto() {
      this.isLoading = true;
      this.images = [];
      const controller = new AbortController();
      this.controllers.set("photosRequest", controller);
      try {
        const token = await this.$auth0.getAccessTokenSilently();
        const response = await getScannerPhotos(
          token,
          this.selectedGroup,
          this.scannerId,
          controller
        );
        if (response) {
          this.images = response.map(({ image }) => {
            const img = new Image();
            img.src = image;
            return img;
          });
        }
        this.isLoading = false;
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Read scanner photo - ${error.message}`, {
            position: "top-right",
          });
          this.isLoading = false;
        }
      }
    },

    async sendImageFiles() {
      try {
        this.isSending = true;
        const controller = new AbortController();
        this.controllers.set("imageUploading", controller);
        const formData = new FormData();
        this.files.forEach((file) => {
          formData.append("upload_file", file);
        });

        const token = await this.$auth0.getAccessTokenSilently();
        const { status } = await postScannerPhotos(
          token,
          this.selectedGroup,
          this.scannerId,
          formData,
          controller
        );

        if (status === 200) {
          this.fetchScannerPhoto();
        }

        this.files = [];
        this.isSending = false;
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Uploading images - ${error.message}`, {
            position: "top-right",
          });
          this.isSending = false;
        }
      }
    },

    removeGallery() {
      if (this.lightbox) {
        this.lightbox.destroy();
        this.lightbox = null;
      }
    },

    async rebuildGallery() {
      this.removeGallery();
      await this.$nextTick();
      this.lightbox = new PhotoSwipeLightbox({
        gallery: "#scanner-gallery",
        children: "a",
        pswpModule: () => import("photoswipe"),
      });
      this.lightbox.init();
    },
  },
  watch: {
    images() {
      this.rebuildGallery();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-section {
  display: flex;
  align-items: center;
  padding: 0 0.5rem 0.5rem;
  .v-btn {
    margin-left: 1rem;
  }
}

#gallery-card {
  .v-card-text {
    height: calc(100% - 40px);
    padding: 0;
  }

  .blank {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 460px;
    height: 100%;
    color: grey;
    font-weight: 600;
    width: 100% !important;
  }

  .v-carousel-item {
    background-color: #e3e3e3;
  }

  .v-icon {
    filter: drop-shadow(0 0 6px #4b4b4b);
  }
}
</style>
