<template>
  <div
    v-if="selectedInspectionStats"
    :class="['statistics-container', isMapMode ? 'flex-column' : '']"
  >
    <div class="gauges-block">
      <StatsGauge
        v-for="gauge in gauges"
        :key="gauge.title"
        v-bind="gauge"
        :showSwitch="isMapMode"
      />
    </div>
    <div class="btns-block">
      <v-expansion-panels mandatory variant="accordion" v-model="accordion">
        <v-expansion-panel value="anomalies">
          <v-expansion-panel-title hide-actions readonly>
            <v-btn
              rounded="xl"
              append-icon="mdi-arrow-right-circle-outline"
              @click="$router.push('/anomalies')"
              >{{ $t("dashboard.pages_btn_name.anomalies") }}</v-btn
            >
          </v-expansion-panel-title>
        </v-expansion-panel>
        <v-expansion-panel value="joints">
          <v-expansion-panel-title>
            <v-btn
              rounded="xl"
              append-icon="mdi-arrow-right-circle-outline"
              @click="$router.push('/joints')"
              >{{ $t("dashboard.pages_btn_name.joints") }}</v-btn
            >
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <p>
              {{ $t("dashboard.pipelines.statistics.histogram_title") }}
              <v-icon
                icon="mdi-information-variant-circle-outline"
                @click="openInfoDialog('joints')"
              ></v-icon>
            </p>
            <PlotlyGraph
              :data="degradationsPlotsData.joints.data"
              :layout="degradationsPlotsData.joints.layout"
              :config="{ displayModeBar: false }"
              style="width: 342px"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel value="parts">
          <v-expansion-panel-title>
            <v-btn
              rounded="xl"
              append-icon="mdi-arrow-right-circle-outline"
              @click="$router.push('/pipe-parts')"
              >{{ $t("dashboard.pages_btn_name.pipe_parts") }}</v-btn
            >
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <p>
              {{ $t("dashboard.pipelines.statistics.histogram_title") }}
              <v-icon
                icon="mdi-information-variant-circle-outline"
                @click="openInfoDialog('joints')"
              ></v-icon>
            </p>
            <PlotlyGraph
              :data="degradationsPlotsData.pipe_parts.data"
              :layout="degradationsPlotsData.pipe_parts.layout"
              :config="{ displayModeBar: false }"
              style="width: 342px"
            />
          </v-expansion-panel-text>
        </v-expansion-panel>
        <v-expansion-panel value="safety-factor">
          <v-expansion-panel-title hide-actions readonly>
            <v-btn
              rounded="xl"
              :disabled="!selectedInspectionStats.safety_factor_available"
              append-icon="mdi-arrow-right-circle-outline"
              @click="$router.push('/safety-factor')"
              >{{ $t("dashboard.pages_btn_name.safety_factor") }}</v-btn
            >
          </v-expansion-panel-title>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <LegendInfoModal
      ref="refLegendInfoModal"
      :page="legendInfoPage"
      selectMode="degradation_level"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { PlotlyGraph } from "@/components";

import { StatsGauge } from "@/components";
import { LegendInfoModal } from "@/components/modals";

import {
  getDegradationColorJoints,
  getDegradationColorPP,
} from "@/colorLegends.js";

export default {
  name: "PipeStatistics",
  components: {
    StatsGauge,
    PlotlyGraph,
    LegendInfoModal,
  },
  data() {
    return {
      accordion: ["joints"],
      legendInfoPage: "",
    };
  },

  computed: {
    ...mapState("pipelines", ["selectedInspectionStats"]),
    ...mapState("map", ["isMapMode"]),
    ...mapGetters("pipelines", ["selectedPipeline"]),

    hasWasteWater() {
      return this.selectedPipeline.medium === "waste-water";
    },

    stats() {
      return this.selectedInspectionStats;
    },

    degradationsPlotsData() {
      return {
        joints: this.getPlotlyData("degradation_level_joints"),
        pipe_parts: this.getPlotlyData("degradation_level_pipe_parts"),
      };
    },

    gauges() {
      const gauges = [];
      // H2S
      if (this.hasWasteWater && this.stats.pipe_parts_material.h2s > 0) {
        gauges.push({
          title: this.$t("dashboard.pipelines.statistics.names.h2s"),
          value: this.stats.h2s_count,
          maxValue: this.stats.pipe_parts_material.h2s,
          geomName: "h2s_geom",
          anyBad: true,
        });
      }
      // AC
      if (this.stats.pipe_parts_material.AC > 0) {
        gauges.push({
          title: this.$t("dashboard.pipelines.statistics.names.local_leaching"),
          value: this.stats.local_leaching_count,
          maxValue: this.stats.pipe_parts_material.AC,
          geomName: "local_leaching_geom",
        });
      }
      // anomaly_count
      if (this.stats.pipe_part_count > 0) {
        gauges.push({
          title: this.$t(
            "dashboard.pipelines.statistics.names.other_anomalies"
          ),
          value: this.stats.anomaly_count || 0,
          maxValue: this.stats.pipe_part_count,
          geomName: "anomaly_geom",
        });
      }
      // crit_joints_angle & crit_joints_gap
      if (this.stats.joints_count > 0) {
        gauges.push({
          title: this.$t(
            "dashboard.pipelines.statistics.names.crit_joints_angle"
          ),
          value: this.stats.critical_joints_count || 0,
          maxValue: this.stats.joints_count,
          geomName: "critical_joints_geom",
        });
        gauges.push({
          title: this.$t(
            "dashboard.pipelines.statistics.names.crit_joints_gap"
          ),
          value: this.stats.critical_joints_count_gap || 0,
          maxValue: this.stats.joints_count,
          geomName: "critical_joints_geom_gap",
        });
      }

      return gauges;
    },
  },

  methods: {
    openInfoDialog(page) {
      this.legendInfoPage = page;
      this.$refs.refLegendInfoModal.open();
    },

    getPlotlyData(type) {
      const x = [];
      const y = [];
      const values = [];
      const colors = [];
      const texts = [];
      for (const key in this.stats[type]) {
        const lvl = key.slice(6);
        const { color, text } =
          type === "degradation_level_joints"
            ? getDegradationColorJoints(this.$t)[lvl]
            : getDegradationColorPP(this.$t)[lvl];

        y.push(this.stats[type][key]);
        x.push(lvl);
        values.push(this.stats[type][key].toString());
        colors.push(color);
        texts.push(text);
      }

      return {
        data: [
          {
            type: "bar",
            y,
            x,
            showlegend: false,
            text: values,
            textposition: "auto",
            textangle: 90,
            hoverinfo: "none",
            marker: {
              color: colors,
            },
          },
        ],
        layout: {
          font: {
            size: 11,
          },
          margin: {
            l: 0,
            r: 20,
            b: 45,
            t: 0,
            pad: 0,
          },
          height: 145,
          yaxis: {
            type: "log",
            fixedrange: true,
            gridcolor: "#fff",
            showticklabels: false,
          },
          xaxis: {
            fixedrange: true,
            gridcolor: "#fff",
            dtick: 1,
            showticklabels: true,
            tickmode: "array",
            tickvals: x,
            ticktext: texts,
            tickangle: 45,
          },
          bargap: 0,
          plot_bgcolor: "#fff",
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.flex-column {
  flex-direction: column;
}

.statistics-container {
  display: flex;
  align-items: center;
  height: 100%;

  .gauges-block {
    padding-bottom: 6px;
    width: 100%;
    flex: 1 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .btns-block {
    width: 390px;
    height: 395px;

    .v-expansion-panels {
      border-top: 1px solid rgba(88, 88, 88, 0.2);
      border-radius: 4px;

      .v-expansion-panel-title {
        padding: 0;
        padding-right: 12px;
        min-height: auto;

        .v-btn {
          width: 200px;
          color: white !important;
          background: $black-primary !important;
          font-size: 0.8rem !important;
          margin: 8px 10px;
        }
      }

      .v-expansion-panel-text__wrapper {
        padding: 4px 24px 16px;

        .v-icon.mdi-information-variant-circle-outline {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
