<template>
  <v-menu
    :model-value="show"
    @update:modelValue="(value) => $emit('update:show', value)"
    :close-on-content-click="false"
    location="bottom"
  >
    <template v-slot:activator="{ props }">
      <v-badge
        dot
        :color="hasActiveFilters ? '#d9534f' : '#ffffff00'"
        :offset-x="6"
      >
        <v-btn
          v-bind="props"
          variant="text"
          density="compact"
          append-icon="mdi-tune"
          >{{ $t("anomalies.filters.title") }}
        </v-btn>
      </v-badge>
    </template>
    <v-card class="filter-content" :width="300" @mousedown.stop>
      <v-card-text>
        <AnomaliesTypesSelector />
        <AnomaliesDistanceRangeSlider class="slider-in-popup" />
        <AnomaliesSelectedLengthSlider class="slider-in-popup" />
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";
import {
  AnomaliesDistanceRangeSlider,
  AnomaliesSelectedLengthSlider,
  AnomaliesTypesSelector,
} from "@/pages/anomaly/views/Anomalies/components";

export default {
  name: "FilterPopupMenu",
  emits: ["update:show"],

  props: {
    show: Boolean,
  },

  computed: {
    ...mapGetters("anomalies", ["hasActiveFilters"]),
  },

  components: {
    AnomaliesDistanceRangeSlider,
    AnomaliesSelectedLengthSlider,
    AnomaliesTypesSelector,
  },
};
</script>

<style lang="scss" scoped>
.slider-in-popup {
  width: 258px;
  padding: 8px 0px 10px;
  text-align: start;
  p {
    font-size: 1rem;
    margin-bottom: 6px;
  }
}
</style>
