export const authMutations = {
  setUserPermissions(state, permissions) {
    state.userPermissions = permissions;
  },
  setSelectedGroup(state, group) {
    state.selectedGroup = group;
  },
  setServiceGroups(state, services) {
    state.serviceGroups = services;
  },
};
