<template>
  <VueDragResize
    :sticks="internalSticks"
    :h="h"
    :class="{ 'content-height-auto': h === 'auto' }"
    class="drag-resize"
    v-bind="$attrs"
  >
    <slot />
  </VueDragResize>
</template>

<script>
import VueDragResize from "vue-drag-resize-via";

export default {
  name: "DragResize",

  components: {
    VueDragResize,
  },

  props: {
    isResizable: {
      type: Boolean,
      default: true,
    },

    sticks: {
      type: Array,
      default: () => ["tl", "tm", "tr", "mr", "br", "bm", "bl", "ml"],
    },

    h: {
      type: [String, Number],
      default: "auto",
    },
  },

  computed: {
    internalSticks() {
      if (!this.isResizable) {
        return [];
      }

      return this.sticks;
    },
  },
};
</script>

<style scoped lang="scss">
.drag-resize {
  // TODO: refactor z-index management
  :deep(.vdr-stick) {
    z-index: 1000;
  }

  :deep(.vdr-stick-tm),
  :deep(.vdr-stick-tl),
  :deep(.vdr-stick-tr) {
    top: 0px !important;
  }

  :deep(.vdr-stick-bm),
  :deep(.vdr-stick-bl),
  :deep(.vdr-stick-br) {
    bottom: 0px !important;
  }
}

.content-height-auto {
  :deep(.content-container) {
    height: fit-content !important;
  }
}
</style>
