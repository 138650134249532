import { mergeChanges } from "@/utils/objects";

export const pipePartsMutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },

  setPipeParts(state, pipeParts) {
    state.pipeParts = pipeParts;
  },

  setFilters(state, filters) {
    mergeChanges(state.filters, filters);
  },

  setSelectedPipePartId(state, selectedPipePartId) {
    state.selectedPipePartId = selectedPipePartId;
  },

  setSelectedGradientMode(state, gradientMode) {
    state.selectedGradientMode = gradientMode;
  },

  setSelectedLegend(state, legend) {
    state.selectedLegend = legend;
  },

  setIsAwaitingDigup(state, isAwaitingDigup) {
    state.isAwaitingDigup = isAwaitingDigup;
  },

  setWallThicknessForLevel(state, payload) {
    const { level, changes } = payload;
    mergeChanges(state.wallThicknessRemaining[level], changes);
  },

  setShowAnomalies(state, areAnomaliesVisible) {
    state.showAnomalies = areAnomaliesVisible;
  },
};
