import { dayjs } from "@/config/dayjs";

import { ScannerType } from "@/pages/scanner-control/config";

export const INITIAL_SCANNER_FILTERS = {
  search: "",
  inspectionDateRange: [
    "2020-01-01",
    dayjs().add(1, "day").format("YYYY-MM-DD"),
  ],
  materials: [],
  diameterRange: [-Infinity, Infinity],
  constuctionYearRange: [String(1900), String(new Date().getFullYear())],
};

export function getInitialScannerControlState() {
  return {
    filters: INITIAL_SCANNER_FILTERS,
    selectedScannerType: ScannerType.Results,
    selectedResultScannerIds: [],
    isAwaitingInspectionExport: false,
    activeScannerIds: {
      [ScannerType.Results]: null,
      [ScannerType.InfoPending]: null,
      [ScannerType.AnalysisPending]: null,
    },
    exportSettings: null,
    scannersService: null,
  };
}

export const scannerControlState = getInitialScannerControlState();
