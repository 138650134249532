<template>
  <div>
    <RangeSlider
      :title="$t('pipe_parts.filters.ovality_max')"
      :model-value="filters.selectedOvalityMax"
      @update:modelValue="(value) => setFilters({ selectedOvalityMax: value })"
      :min="minMaxOvalityMax[0]"
      :max="minMaxOvalityMax[1]"
      :step="0.01"
    ></RangeSlider>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { RangeSlider } from "@/components";

export default {
  computed: {
    ...mapState("pipeParts", ["filters"]),
    ...mapGetters("pipeParts", ["minMaxOvalityMax"]),
  },
  methods: {
    ...mapMutations("pipeParts", ["setFilters"]),
  },
  components: {
    RangeSlider,
  },
};
</script>
