<template>
  <div class="slider">
    <RangeSlider
      :title="$t('anomalies.filters.length')"
      :model-value="anomalyFilters.selectedLength"
      @update:modelValue="(value) => setFilters({ selectedLength: value })"
      :min="minMaxLength[0]"
      :max="minMaxLength[1]"
      :step="0.01"
    />
  </div>
</template>

<script>
import RangeSlider from "@/components/RangeSlider";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("anomalies", ["anomalyFilters"]),
    ...mapGetters("anomalies", ["minMaxLength"]),
  },

  methods: {
    ...mapMutations("anomalies", ["setFilters"]),
  },

  components: {
    RangeSlider,
  },
};
</script>

<style lang="scss" scoped>
.slider {
  width: 25%;
  text-align: center;
}
</style>
