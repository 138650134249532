<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      ref="modifiedMapRef"
      :showLoader="isLoading"
      :wmsLayers="wmsLayers"
      :widgets="widgets"
      @mb-moveend="onMoveend"
      @click="selectedWidget = null"
    >
      <template v-if="selectedPipePart">
        <MapboxMarker
          :lng-lat="selectedPipePart.geom.coordinates[0]"
          :scale="0.9"
          color="orange"
        >
        </MapboxMarker>
        <MapboxPopup :lng-lat="selectedPipePart.geom.coordinates[0]">
          <MapInfoPopup
            :data="selectedPipePart"
            type="pipepart"
            :activeValue="
              gradientOptions.find(
                (option) => option.value === selectedGradientMode
              )
            "
          />
        </MapboxPopup>
      </template>

      <template v-slot:sources="{ openDigupMenu }">
        <template
          v-for="{ id, sourceOptions, layerOptions } in pipePartsMapData"
          :key="id"
        >
          <MapboxSource :id="id" :options="sourceOptions" />
          <MapboxLayer
            :id="layerOptions.id"
            :options="layerOptions"
            @mb-mouseenter="onLayerMouseEnter"
            @mb-mouseleave="onLayerMouseLeave"
            @mb-click="onLayerClick"
            @mb-contextmenu="onLayerContextmenu(layerOptions.id, openDigupMenu)"
          />
        </template>
      </template>

      <template v-slot:append>
        <WidgetsList
          :widgets="widgets"
          :widgetsBarConfig="widgetsBarConfig"
          v-if="!isLoading"
        >
          <template v-slot:[`${PipePartsWidget.PipePartsTable}-title-prepend`]>
            <FilterPopupMenu
              v-model:show="showFilter"
              :typeOptions="typeOptions"
              :materialOptions="materialOptions"
            />
          </template>
          <template v-slot:[`${PipePartsWidget.PipePartsTable}-content`]>
            <PipePartsTable />
          </template>

          <template v-slot:[`${PipePartsWidget.Histogram}-content`]>
            <PipePartsHistogram :title="histogramTitle" />
          </template>

          <template v-slot:[`${PipePartsWidget.HeightProfileChart}-content`]>
            <PipePartsHeightChart
              :visibleParts="visibleParts"
              @graph-mousedown="selectedWidget = 'heightProfile'"
            />
          </template>

          <template v-slot:[`${PipePartsWidget.Radar}-content`]>
            <PipePartsRadar
              :title="radarTitle"
              @graph-mousedown="selectedWidget = 'histogram'"
            />
          </template>

          <template v-slot:[`${PipePartsWidget.GradientLegend}-title-prepend`]>
            <GradientLegendToolbar @click:info="showInfoModal()" />
          </template>
          <template v-slot:[`${PipePartsWidget.GradientLegend}-content`]>
            <PipePartsGradient :gradientColor="gradientColor" />
          </template>

          <template v-slot:[`${PipePartsWidget.ColorsLegend}-title-prepend`]>
            <ColorsLegendToolbar @click:info="showInfoModal()" />
          </template>
          <template v-slot:[`${PipePartsWidget.ColorsLegend}-content`]>
            <PipePartsLegend :title="histogramTitle" />
          </template>

          <template v-slot:[`${PipePartsWidget.WMSWidget}-content`]>
            <WMSWidget
              v-model:wmsLayers="wmsLayers"
              :colorMode="selectedGradientMode"
              :colorOptions="gradientOptions"
              @select-color-mode="setSelectedGradientMode"
            />
          </template>

          <template v-slot:[`${PipePartsWidget.WMSLegend}-content`]>
            <WMSLegend :wmsLayers="wmsLayers" :currentZoom="currentZoom" />
          </template>
        </WidgetsList>
      </template>
    </ModifiedMapbox>
    <ThresholdsModal ref="refThresholdsModal" />
    <LegendInfoModal
      ref="refLegendInfoModal"
      page="pipeParts"
      :selectMode="selectedGradientMode"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import {
  MapboxMarker,
  MapboxPopup,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import { MapInfoPopup, ModifiedMapbox } from "@/components";
import { WMSLegend, WMSWidget } from "@/features/wms/components";
import {
  FilterPopupMenu,
  PipePartsLegend,
  ColorsLegendToolbar,
  GradientLegendToolbar,
} from "./components";
import {
  PipePartsTable,
  PipePartsHistogram,
  PipePartsHeightChart,
  PipePartsRadar,
  PipePartsGradient,
} from "@/pages/pipe-parts/components";
import { ThresholdsModal, LegendInfoModal } from "@/components/modals";
import { WidgetsList } from "@/components/map-componets/widgets";

import { PipePartsWidget } from "./config";

export default {
  name: "PipePartsMapmode",

  components: {
    MapboxMarker,
    MapboxPopup,
    MapboxLayer,
    MapboxSource,
    MapInfoPopup,
    PipePartsLegend,
    PipePartsGradient,
    FilterPopupMenu,
    PipePartsTable,
    ThresholdsModal,
    LegendInfoModal,
    PipePartsHistogram,
    PipePartsHeightChart,
    PipePartsRadar,
    WMSLegend,
    WMSWidget,
    ModifiedMapbox,
    WidgetsList,
    ColorsLegendToolbar,
    GradientLegendToolbar,
  },

  props: {
    pipePartsMapData: Array,

    typeOptions: Array,
    materialOptions: Array,
    gradientOptions: Array,

    gradientColor: Array,

    histogramTitle: String,
    radarTitle: String,
  },

  setup() {
    return {
      PipePartsWidget,
    };
  },

  data() {
    return {
      visibleParts: [],
      currentZoom: null,
      wmsLayers: [],
      showFilter: false,
    };
  },

  computed: {
    ...mapState("pipeParts", [
      "isLoading",
      "partsSelector",
      "selectedGradientMode",
      "showAnomalies",
    ]),

    ...mapGetters("pipeParts", [
      "convertedPipeParts",
      "filteredPipeParts",
      "selectedPipePart",
      "minMaxDistance",
      "hasActiveFilters",
    ]),

    showGradient() {
      return ![
        "quality_anomaly",
        "degradation_level",
        "remaining_life_calculation.remaining_life_group",
        "material",
        "remaining_life_calculation.road_type",
        "remaining_life_calculation.soil_type",
      ].includes(this.selectedGradientMode);
    },

    widgets() {
      return {
        [PipePartsWidget.PipePartsTable]: {
          key: PipePartsWidget.PipePartsTable,
          title: this.$t("pipe_parts.widgets.table.maximize_title"),
          initialWidth: 780,
          initialHeight: 355,
          getInitialX: (container, widgets) =>
            container.offsetWidth -
            (widgets[PipePartsWidget.PipePartsTable].initialWidth + 10),
          getInitialY: () => 150,
          initiallyMinimized: false,
        },

        [PipePartsWidget.Histogram]: {
          key: PipePartsWidget.Histogram,
          title: this.histogramTitle,
          initialWidth: 655,
          initialHeight: 400,
          getInitialX: () => 5,
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[PipePartsWidget.Histogram].initialHeight + 35),
          initiallyMinimized: false,
        },

        [PipePartsWidget.HeightProfileChart]: {
          key: PipePartsWidget.HeightProfileChart,
          title: this.$t("pipe_parts.widgets.heightProfile.maximize_title"),
          initialWidth: 655,
          initialHeight: 400,
          getInitialX: () => 5,
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[PipePartsWidget.HeightProfileChart].initialHeight + 180),
          initiallyMinimized: true,
        },

        [PipePartsWidget.Radar]: {
          key: PipePartsWidget.Radar,
          title: this.radarName,
          initialWidth: 400,
          initialHeight: 400,
          getInitialX: (container, widgets) =>
            container.offsetWidth -
            (widgets[PipePartsWidget.Radar].initialWidth + 5),
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[PipePartsWidget.Radar].initialHeight + 45),
          initiallyMinimized: true,
        },

        [PipePartsWidget.GradientLegend]: {
          key: PipePartsWidget.GradientLegend,
          title: this.$t("joints.widgets.gradient.maximize_title"),
          initialWidth: 130,
          initialHeight: "auto",
          getInitialX: () => 5,
          getInitialY: () => 50,
          initiallyMinimized: false,
          hidden: !this.showGradient,

          displayConfig: {
            isResizable: false,
            isMinimizable: false,
            isDraggable: false,
          },
        },

        [PipePartsWidget.ColorsLegend]: {
          key: PipePartsWidget.ColorsLegend,
          title: this.$t("joints.widgets.gradient.maximize_title"),
          initialWidth: 230,
          initialHeight: "auto",
          getInitialX: () => 5,
          getInitialY: () => 50,
          initiallyMinimized: false,
          hidden: this.showGradient,

          displayConfig: {
            isResizable: false,
            isMinimizable: false,
            isDraggable: false,
          },
        },

        [PipePartsWidget.WMSWidget]: {
          key: PipePartsWidget.WMSWidget,
          title: this.$t("dashboard.widgets.color.maximize_title"),
          initialWidth: 350,
          initialHeight: "auto",
          getInitialX: (container, widgets) =>
            (container.offsetWidth -
              widgets[PipePartsWidget.WMSWidget].initialWidth) /
            2,
          getInitialY: () => 5,
          initiallyMinimized: false,

          displayConfig: {
            isResizable: false,
          },
        },

        [PipePartsWidget.WMSLegend]: {
          key: PipePartsWidget.WMSLegend,
          title: this.$t("wms_layers.layers"),
          initialWidth: 250,
          initialHeight: 365,
          getInitialX: () => 5,
          getInitialY: () => 180,
          initiallyMinimized: false,
          hidden: this.wmsLayers.length === 0,
        },
      };
    },

    widgetsBarConfig() {
      return {
        [PipePartsWidget.PipePartsTable]: {
          showFiltersIndicator: this.hasActiveFilters,
        },
      };
    },

    radarName() {
      if (
        this.selectedGradientMode?.includes("remaining_") ||
        this.selectedGradientMode?.includes("degradated_total_")
      ) {
        return this.gradientOptions.find(
          (opt) => opt.value === this.selectedGradientMode
        ).title;
      }
      return this.gradientOptions.find((opt) => opt.value === "remaining_mean")
        .title;
    },

    map() {
      return this.$refs.modifiedMapRef.map;
    },
  },

  methods: {
    ...mapMutations("pipeParts", [
      "setSelectedPipePartId",
      "setSelectedGradientMode",
    ]),

    ...mapActions("pipeParts", ["toggleAnomaliesVisibility"]),

    showThresholdsModal() {
      this.$refs.refThresholdsModal?.showModal();
    },

    showInfoModal() {
      this.$refs.refLegendInfoModal?.open();
    },

    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
      const layerId = this.pipePartsMapData.find(({ layerOptions }) =>
        layerOptions.id.includes("layer_pipe_parts")
      )?.layerOptions.id;

      if (map.getLayer(layerId)) {
        const visiblePartsIds = map
          .queryRenderedFeatures({ layers: [layerId] })
          .map((pp) => pp.properties.id);
        this.visibleParts = this.convertedPipeParts.filter((pp) =>
          visiblePartsIds.includes(pp.id)
        );
      }
    },

    onLayerContextmenu(layeerId, callback) {
      if (layeerId.includes("selected_pipe_parts")) {
        callback();
      }
    },

    onLayerClick({ features, originalEvent }) {
      const { ctrlKey, shiftKey } = originalEvent;
      if (features[0].source.includes("pipe_parts")) {
        const selectedId = features[0].properties.id;
        if (ctrlKey) {
          this.partsSelector.setByCtrl(selectedId);
        } else if (shiftKey) {
          this.partsSelector.setByShift(selectedId, this.filteredPipeParts);
        } else {
          this.setSelectedPipePartId(null);
          Promise.resolve().then(() => this.setSelectedPipePartId(selectedId));
        }
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features[0].source.includes("pipe_parts")) {
        target.getCanvas().style.cursor = "pointer";
        this.map.boxZoom.disable();
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
      this.map.boxZoom.enable();
    },
  },

  watch: {
    selectedPipePart(part) {
      if (part) {
        this.map.flyTo({
          center: part.geom.coordinates[0],
          zoom: this.currentZoom < 17 ? 17 : this.currentZoom,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.hide {
  opacity: 0;
}

.anomalies-toggler {
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;
  .v-switch {
    margin-right: 1rem;
  }
}

.zIndex {
  z-index: 5 !important;
}

.inactive {
  opacity: 0.8;
}
</style>
