import {
  ANALYSIS_PENDING,
  INFO_PENDING,
  RESULTS,
} from "@/features/pipescanner/constants";
import { mergeChanges } from "@/utils/objects";

export const pipeScannersMutations = {
  setPipeScanners(state, changes) {
    mergeChanges(state.pipeScanners, changes);
  },
  setIsLoading(state, changes) {
    mergeChanges(state.isLoading, changes);
  },
  setScanners(state, { key, scanners }) {
    state.pipeScanners[key] = scanners;
  },
  updateScanner(state, { key, scannerId, updateForm }) {
    state.pipeScanners[key] = state.pipeScanners[key].map((scanner) => {
      if (scanner.id !== scannerId) {
        return scanner;
      }
      const updatedScanner = { ...scanner };
      const fields = Object.getOwnPropertyNames(updateForm);
      fields.forEach((field) => {
        if (updatedScanner.hasOwnProperty(field)) {
          updatedScanner[field] = updateForm[field];
        }
      });
      return updatedScanner;
    });
  },
  resetScanners(state) {
    state.pipeScanners.pipeScanners = {
      [RESULTS]: [],
      [INFO_PENDING]: [],
      [ANALYSIS_PENDING]: [],
    };
  },
};
