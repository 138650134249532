<template>
  <v-select
    :label="$t('anomalies.filters.materials')"
    :model-value="anomalyFilters.selectedMaterials"
    @update:modelValue="(value) => setFilters({ selectedMaterials: value })"
    :items="materialsOptions"
    item-title="title"
    item-value="value"
    multiple
    variant="underlined"
  >
  </v-select>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("anomalies", ["anomalyFilters"]),
    ...mapGetters("anomalies", ["anomaliesTypes", "filterMaterials"]),

    anomaliesTypesOptions() {
      return this.anomaliesTypes.map((type) => ({
        value: type,
        title: this.$t(`anomalies.types.${type}`),
      }));
    },

    materialsOptions() {
      return this.filterMaterials.map((material) => ({
        value: material,
        title: this.$t(`materials.${material}`),
      }));
    },
  },
  methods: {
    ...mapMutations("anomalies", ["setFilters"]),
  },
};
</script>
