<template>
  <RangeSlider
    :disabled="selectedScannerType !== ScannerType.Results"
    :title="`${$t('pipe_scanner.filters.diameter')}:`"
    :model-value="filters.diameterRange"
    @update:modelValue="(value) => setFilters({ diameterRange: value })"
    :min="minMaxDiameters[0]"
    :max="minMaxDiameters[1]"
    :step="1"
  />
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { RangeSlider } from "@/components";
import { ScannerType } from "@/pages/scanner-control/config";

export default {
  setup() {
    return {
      ScannerType,
    };
  },
  computed: {
    ...mapState("scannerControl", ["selectedScannerType", "filters"]),
    ...mapGetters("scannerControl", ["minMaxDiameters"]),
  },
  methods: {
    ...mapMutations("scannerControl", ["setFilters"]),
  },
  components: {
    RangeSlider,
  },
};
</script>
