<template>
  <TableContainer v-if="filteredPipeParts.length > 0" :resizable="isMapMode">
    <SafetyTableMapmode
      v-if="isMapMode"
      :filteredPipeParts="filteredPipeParts"
      :isPipePartActive="isPipePartActive"
      @click:row="(pipePartId) => onSelectPipePartId(pipePartId)"
    />
    <SafetyTableRegular
      v-else
      :filteredPipeParts="filteredPipeParts"
      :isPipePartActive="isPipePartActive"
      @click:row="(pipePartId) => onSelectPipePartId(pipePartId)"
    />
  </TableContainer>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

import SafetyTableMapmode from "./SafetyTableMapmode";
import SafetyTableRegular from "./SafetyTableRegular";
import { TableContainer } from "@/components/table-components/containers";

export default {
  name: "SafetyTable",

  computed: {
    ...mapState("map", ["isMapMode"]),

    ...mapGetters("safetyFactor", ["selectedPipePart", "filteredPipeParts"]),
  },

  components: {
    SafetyTableMapmode,
    SafetyTableRegular,
    TableContainer,
  },

  methods: {
    ...mapMutations("safetyFactor", ["setSelectedPipePartId"]),

    onSelectPipePartId(id) {
      this.setSelectedPipePartId(id);
    },

    isPipePartActive(pipePart) {
      return pipePart.id === this.selectedPipePart?.id;
    },
  },
};
</script>
