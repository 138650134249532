<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      ref="modifiedMapRef"
      :showLoader="isLoading"
      :wmsLayers="wmsLayers"
      :widgets="widgets"
      forceHideScanners
      @mb-moveend="onMoveend"
    >
      <MapboxMarker
        v-if="selectedPipePart"
        :lng-lat="selectedPipePart.geom.coordinates[0]"
        :scale="0.9"
        color="orange"
      />

      <template v-slot:sources>
        <template
          v-for="{ id, sourceOptions, layerOptions } in mapData"
          :key="id"
        >
          <MapboxSource :id="id" :options="sourceOptions" />
          <MapboxLayer
            :id="layerOptions.id"
            :options="layerOptions"
            @mb-mouseenter="onLayerMouseEnter"
            @mb-mouseleave="onLayerMouseLeave"
            @mb-click="onLayerClick"
          />
        </template>
      </template>

      <template v-slot:append>
        <WidgetsList
          :widgets="widgets"
          :widgetsBarConfig="widgetsBarConfig"
          v-if="!isLoading"
        >
          <template
            v-slot:[`${SafetyFactorWidget.PipePartsTable}-title-prepend`]
          >
            <FilterPopupMenu
              v-model:show="showFilter"
              :uncertaintiesOptions="uncertaintiesOptions"
            />
          </template>
          <template v-slot:[`${SafetyFactorWidget.PipePartsTable}-content`]>
            <SafetyTable />
          </template>

          <template v-slot:[`${SafetyFactorWidget.SafetyChart}-content`]>
            <SafetyChart :visibleParts="visibleParts" />
          </template>

          <template v-slot:[`${SafetyFactorWidget.ContributionsChart}-content`]>
            <UncertaintyContributionsChart :visibleParts="visibleParts" />
          </template>

          <template v-slot:[`${SafetyFactorWidget.HeightProfileChart}-content`]>
            <SafetyHeightChart :visibleParts="visibleParts" />
          </template>

          <template v-slot:[`${SafetyFactorWidget.ColorsLegend}-content`]>
            <SafetyColorsLegend />
          </template>

          <template v-slot:[`${SafetyFactorWidget.GradientLegend}-content`]>
            <SafetyGradient />
          </template>

          <template v-slot:[`${SafetyFactorWidget.WMSWidget}-content`]>
            <WMSWidget
              v-model:wmsLayers="wmsLayers"
              :colorMode="selectedViewFactor"
              :colorOptions="safetyFactorOptions"
              @select-color-mode="setSelectedViewFactor"
            />
          </template>

          <template v-slot:[`${SafetyFactorWidget.WMSLegend}-content`]>
            <WMSLegend :wmsLayers="wmsLayers" :currentZoom="currentZoom" />
          </template>
        </WidgetsList>
      </template>
    </ModifiedMapbox>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

import {
  MapboxMarker,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import {
  SafetyColorsLegend,
  SafetyHeightChart,
  SafetyGradient,
  SafetyTable,
  SafetyChart,
  UncertaintyContributionsChart,
} from "@/pages/safety-factor/components";
import { ModifiedMapbox } from "@/components";
import { WMSLegend, WMSWidget } from "@/features/wms/components";
import { FilterPopupMenu } from "./components";
import { WidgetsList } from "@/components/map-componets/widgets";

import { VIEW_FACTORS_WITHOUT_GRADIENT } from "@/pages/safety-factor/config";
import { SafetyFactorWidget } from "./config";

export default {
  name: "SafetyFactorMapmode",

  components: {
    MapboxMarker,
    MapboxLayer,
    MapboxSource,
    WMSLegend,
    WMSWidget,
    UncertaintyContributionsChart,
    SafetyColorsLegend,
    SafetyHeightChart,
    SafetyGradient,
    SafetyChart,
    SafetyTable,
    FilterPopupMenu,
    ModifiedMapbox,
    WidgetsList,
  },

  props: {
    mapData: Array,
    safetyFactorOptions: Array,
    uncertaintiesOptions: Array,
    safetyChartTitle: String,
    legendTitle: String,
  },

  setup() {
    return {
      SafetyFactorWidget,
    };
  },

  data() {
    return {
      visibleParts: [],
      wmsLayers: [],
      currentZoom: null,
      showFilter: false,
    };
  },

  computed: {
    ...mapState("map", ["mapStyle"]),

    ...mapState("safetyFactor", [
      "isLoading",
      "selectedViewFactor",
    ]),
    ...mapState("pipeParts", [
      "pipeParts",
    ]),

    ...mapGetters("safetyFactor", ["selectedPipePart", "hasActiveFilters"]),

    widgets() {
      return {
        [SafetyFactorWidget.PipePartsTable]: {
          key: SafetyFactorWidget.PipePartsTable,
          title: this.$t("safety_factor.widgets.table"),
          initialWidth: 840,
          initialHeight: 355,
          getInitialX: (container, widgets) =>
            container.offsetWidth -
            (widgets[SafetyFactorWidget.PipePartsTable].initialWidth + 10),
          getInitialY: () => 175,
          initiallyMinimized: false,
        },

        [SafetyFactorWidget.SafetyChart]: {
          key: SafetyFactorWidget.SafetyChart,
          title: this.safetyChartTitle,
          initialWidth: 655,
          initialHeight: 400,
          getInitialX: () => 5,
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[SafetyFactorWidget.SafetyChart].initialHeight + 35),
          initiallyMinimized: false,
        },

        [SafetyFactorWidget.ContributionsChart]: {
          key: SafetyFactorWidget.ContributionsChart,
          title: this.$t("safety_factor.widgets.contributions_chart"),
          initialWidth: 655,
          initialHeight: 400,
          getInitialX: (container, widgets) =>
            container.offsetWidth -
            (widgets[SafetyFactorWidget.ContributionsChart].initialWidth + 30),
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[SafetyFactorWidget.ContributionsChart].initialHeight + 35),
          initiallyMinimized: true,
        },

        [SafetyFactorWidget.HeightProfileChart]: {
          key: SafetyFactorWidget.HeightProfileChart,
          title: this.$t("safety_factor.widgets.height_profile"),
          initialWidth: 655,
          initialHeight: 400,
          getInitialX: () => 5,
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[SafetyFactorWidget.HeightProfileChart].initialHeight +
              180),
          initiallyMinimized: true,
        },

        [SafetyFactorWidget.GradientLegend]: {
          key: SafetyFactorWidget.GradientLegend,
          title: this.$t("joints.widgets.gradient.maximize_title"),
          initialWidth: 130,
          initialHeight: "auto",
          getInitialX: () => 5,
          getInitialY: () => 50,
          initiallyMinimized: false,
          hidden: !this.showGradient,

          displayConfig: {
            isResizable: false,
            isDraggable: false,
            isMinimizable: false,
          },
        },

        [SafetyFactorWidget.ColorsLegend]: {
          key: SafetyFactorWidget.ColorsLegend,
          title: this.legendTitle,
          initialWidth: 180,
          initialHeight: "auto",
          getInitialX: () => 5,
          getInitialY: () => 50,
          initiallyMinimized: false,
          hidden: this.showGradient,

          displayConfig: {
            isResizable: false,
            isDraggable: false,
            isMinimizable: false,
          },
        },

        [SafetyFactorWidget.WMSWidget]: {
          key: SafetyFactorWidget.WMSWidget,
          title: this.$t("safety_factor.widgets.color"),
          initialWidth: 350,
          initialHeight: "auto",
          getInitialX: (container, widgets) =>
            (container.offsetWidth -
              widgets[SafetyFactorWidget.WMSWidget].initialWidth) /
            2,
          getInitialY: () => 5,
          initiallyMinimized: false,

          displayConfig: {
            isResizable: false,
          },
        },

        [SafetyFactorWidget.WMSLegend]: {
          key: SafetyFactorWidget.WMSLegend,
          title: this.$t("wms_layers.layers"),
          initialWidth: 250,
          initialHeight: 365,
          getInitialX: () => 5,
          getInitialY: () => 180,
          initiallyMinimized: false,
          hidden: this.wmsLayers.length === 0,
        },
      };
    },

    widgetsBarConfig() {
      return {
        [SafetyFactorWidget.PipePartsTable]: {
          showFiltersIndicator: this.hasActiveFilters,
        },
      };
    },

    map() {
      return this.$refs.modifiedMapRef.map;
    },

    showGradient() {
      return !VIEW_FACTORS_WITHOUT_GRADIENT.includes(this.selectedViewFactor);
    },
  },

  methods: {
    ...mapMutations("safetyFactor", [
      "setSelectedPipePartId",
      "setSelectedViewFactor",
    ]),

    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
      const layerId = this.mapData.find(({ layerOptions }) =>
        layerOptions.id.includes("layer_pipe_parts")
      )?.layerOptions.id;

      if (map.getLayer(layerId)) {
        const visiblePartsIds = map
          .queryRenderedFeatures({ layers: [layerId] })
          .map((part) => part.properties.id);
        const sorted = [];
        this.pipeParts.forEach((part, index) => {
          if (visiblePartsIds.includes(part.id)) {
            sorted.push({ ...part, order_index: index });
          }
        });

        this.visibleParts = sorted;
      }
    },

    onLayerClick({ features }) {
      if (features && features[0].source.includes("pipe_parts")) {
        this.setSelectedPipePartId(features[0].properties.id);
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features && features[0].source.includes("pipe_parts")) {
        target.getCanvas().style.cursor = "pointer";
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
    },
  },

  watch: {
    selectedPipePart(selected, prevSelected) {
      if (selected && selected.id !== prevSelected?.id) {
        this.map.flyTo({
          center: selected.geom.coordinates[0],
          zoom: this.currentZoom < 17 ? 17 : this.currentZoom,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.hide {
  opacity: 0;
}

.zIndex {
  z-index: 5 !important;
}

.inactive {
  opacity: 0.8;
}
</style>
