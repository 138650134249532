<template>
  <div>
    <slot name="title"></slot>
    <RangeSlider
      :title="title"
      :model-value="filters.selectedUpperSafetyRange"
      @update:modelValue="
        (value) => setFilters({ selectedUpperSafetyRange: value })
      "
      :min="minMaxUpperSafetyRange[0]"
      :max="minMaxUpperSafetyRange[1]"
      :step="0.01"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { RangeSlider } from "@/components";

export default {
  data() {
    return {
      title: undefined,
    };
  },
  computed: {
    ...mapState("safetyFactor", ["filters"]),
    ...mapGetters("safetyFactor", ["minMaxUpperSafetyRange"]),
  },
  methods: {
    ...mapMutations("safetyFactor", ["setFilters"]),
  },
  mounted() {
    if (!this.$slots.title || !this.$slots.title()?.length) {
      this.title = this.$t("safety_factor.filters.safety_factor_upper");
    }
  },
  components: {
    RangeSlider,
  },
};
</script>
