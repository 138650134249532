import { DigUpSelector } from "@/features/dig-up/helpers";
import { i18n } from "@/config/i18n";

export const INITIAL_PLOTLY_FILTER = {
  property: "",
  range: [],
};

export const INITIAL_PIPE_PART_FILTERS = {
  selectedDistance: [],
  selectedPipePartTypes: [],
  selectedMaterials: [],
  selectedOvalityMax: [],
  selectedOvalityMean: [],
  selectedDeformationHor: [],
  selectedDeformationVer: [],
  plotlyFilter: INITIAL_PLOTLY_FILTER,
};

export const pipePartsState = {
  pipeParts: [],
  isLoading: true,
  isAwaitingDigup: false,
  filters: INITIAL_PIPE_PART_FILTERS,
  selectedPipePartId: null,
  selectedGradientMode: "degradation_level",
  selectedLegend: null,
  partsSelector: new DigUpSelector(i18n.global.locale),
  showAnomalies: false,

  wallThicknessRemaining: {
    mean: {
      isMultipleLayers: false,
      layerNum: 0,
      length: 0,
    },
    min: {
      isMultipleLayers: false,
      layerNum: 0,
      length: 0,
    },
    max: {
      isMultipleLayers: false,
      layerNum: 0,
      length: 0,
    },
    std: {
      isMultipleLayers: false,
      layerNum: 0,
      length: 0,
    },
  },
};
