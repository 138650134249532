<template>
  <div>
    <slot name="title"></slot>
    <RangeSlider
      :title="title"
      :model-value="filters.selectedSafetyRange"
      @update:modelValue="(value) => setFilters({ selectedSafetyRange: value })"
      :min="minMaxSafetyRange[0]"
      :max="minMaxSafetyRange[1]"
      :step="0.01"
    />
  </div>
</template>

<script>
import { RangeSlider } from "@/components";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      title: undefined,
    };
  },
  computed: {
    ...mapState("safetyFactor", ["filters"]),
    ...mapGetters("safetyFactor", ["minMaxSafetyRange"]),
  },
  methods: {
    ...mapMutations("safetyFactor", ["setFilters"]),
  },
  mounted() {
    if (!this.$slots.title || !this.$slots.title()?.length) {
      this.title = this.$t("safety_factor.filters.safety_factor");
    }
  },
  components: {
    RangeSlider,
  },
};
</script>
