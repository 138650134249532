<template>
  <VirtualTable
    :headers="headers"
    :items="filteredJoints"
    :is-item-active="isJointActive"
    @click:row="(joint) => $emit('click:row', joint.id)"
    showSelect
  >
    <template v-slot:[`header.select`]>
      <TableButton
        :disabled="checkedLength === 0"
        @click="jointsSelector.clear()"
        variant="text"
        icon="mdi-close-box"
      />
    </template>
    <template v-slot:[`item.select`]="{ item: joint }">
      <TableCheckbox
        :modelValue="jointsSelector.has(joint.id)"
        :disabled="
          shouldDisableCheckbox(joint.id, filteredJoints, jointsSelector)
        "
        @update:modelValue="jointsSelector.setByCtrl(joint.id)"
        @click.stop
      />
    </template>
  </VirtualTable>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { VirtualTable } from "@/components";
import {
  TableCheckbox,
  TableButton,
} from "@/components/table-components/controls";

import { shouldDisableCheckbox } from "@/features/dig-up/helpers";

export default {
  name: "JointsTableRegular",

  props: {
    checkedLength: Number,
    isJointActive: Function,
  },

  components: { VirtualTable, TableButton, TableCheckbox },

  emits: ["click:row"],

  setup() {
    return {
      shouldDisableCheckbox,
    };
  },

  data() {
    return {
      headers: [
        {
          title: this.$t("joints.table_titles.joint_id"),
          key: "joint_id",
          value: "joint_id",
        },
        {
          title: this.$t("joints.table_titles.joint_type"),
          key: "joint_type",
          value: (joint) => this.$t(`joints.types.${joint.joint_type}`),
        },
        {
          title: this.$t("joints.table_titles.material"),
          key: "material",
          value: (joint) => this.$t(`materials.${joint.material}`),
        },
        {
          title: this.$t("joints.table_titles.angle_vertical"),
          key: "angle_vertical",
          value: (joint) => Number(joint.angle_vertical).toFixed(2),
          units: "°",
        },
        {
          title: this.$t("joints.table_titles.angle_horizontal"),
          key: "angle_horizontal",
          value: (joint) => Number(joint.angle_horizontal).toFixed(2),
          units: "°",
        },
        {
          title: this.$t("joints.table_titles.gap_width_mean"),
          key: "gap_width_mean",
          value: "gap_width_mean",
          units: "mm",
        },
        {
          title: this.$t("joints.table_titles.gap_width_max"),
          key: "gap_width_max",
          value: "gap_width_max",
          units: "mm",
        },
        {
          title: this.$t("joints.table_titles.degradation_level"),
          key: "degradation_level",
          value: "degradation_level",
        },
      ],
    };
  },

  computed: {
    ...mapState("joints", ["jointsSelector"]),

    ...mapGetters("joints", ["filteredJoints"]),
  },
};
</script>
