<template>
  <div class="slider">
    <RangeSlider
      :title="$t('joints.filters.gap_width_max')"
      :model-value="jointFilters.selectedGapMax"
      @update:modelValue="(value) => setFilters({ selectedGapMax: value })"
      :min="jointFilters.selectedGapMax[0]"
      :max="jointFilters.selectedGapMax[1]"
      :step="$units.current === 'metric' ? 1 : 0.01"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { RangeSlider } from "@/components";

export default {
  computed: {
    ...mapState("joints", ["jointFilters"]),
  },
  methods: {
    ...mapMutations("joints", ["setFilters"]),
  },
  components: {
    RangeSlider,
  },
};
</script>

<style scoped lang="scss">
.slider {
  width: 25%;
}
</style>
