<template>
  <v-select
    :label="$t('safety_factor.filters.main_contributor')"
    :model-value="filters.selectedMainUncertainties"
    @update:modelValue="
      (value) => setFilters({ selectedMainUncertainties: value })
    "
    :items="items"
    item-title="text"
    item-value="text"
    multiple
    variant="underlined"
  >
  </v-select>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: {
    items: Array,
  },
  computed: {
    ...mapState("safetyFactor", ["filters"]),
  },
  methods: {
    ...mapMutations("safetyFactor", ["setFilters"]),
  },
};
</script>
