export const pipelinesMutations = {
  setIsLoadingPipelines(state, isLoading) {
    state.isLoadingPipelines = isLoading;
  },

  setIsLoadingStats(state, isLoading) {
    state.isLoadingStats = isLoading;
  },

  setGroupPipelines(state, pipelines) {
    state.groupPipelines = pipelines;
  },

  setSelectedPipelineId(state, pipeline) {
    state.selectedPipelineId = pipeline;
  },

  setPipelineElevation(state, elevation) {
    state.pipelineElevation = elevation;
  },

  setComments(state, comments) {
    state.comments = comments;
  },

  updateComment(state, comment) {
    const editedIndex = state.comments.findIndex(({ id }) => id === comment.id);
    if (editedIndex >= 0) {
      state.comments[editedIndex] = comment;
    } else {
      state.comments.push(comment);
    }
  },

  removeComment(state, commentId) {
    state.comments = state.comments.filter(
      (comment) => comment.id !== commentId
    );
  },

  setSelectedInspectionId(state, inspection) {
    state.selectedInspectionId = inspection;
  },

  setSelectedInspectionStats(state, stats) {
    state.selectedInspectionStats = stats;
  },
};
