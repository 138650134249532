import { getInitialScannerControlState } from "@/store/scannerControl/state";
import { mergeChanges } from "@/utils/objects";

export const scannerControlMutations = {
  setFilters(state, changes = {}) {
    mergeChanges(state.filters, changes);
  },

  setActiveScannerIds(state, changes) {
    mergeChanges(state.activeScannerIds, changes);
  },

  setSelectedScannerType(state, newType) {
    state.selectedScannerType = newType;
  },

  addSelectedResultScannerId(state, newId) {
    state.selectedResultScannerIds.push(newId);
  },

  removeSelectedResultScannerId(state, newId) {
    state.selectedResultScannerIds = state.selectedResultScannerIds.filter(
      (id) => id !== newId
    );
  },

  clearSelectedResultScannerIds(state) {
    state.selectedResultScannerIds = [];
  },

  setSelectedResultScannerIds(state, ids) {
    state.selectedResultScannerIds = ids;
  },

  setIsAwaitingInspectionExport(state, isAwaitingExport) {
    state.isAwaitingInspectionExport = isAwaitingExport;
  },

  resetState(state) {
    Object.assign(state, getInitialScannerControlState());
  },

  setExportSettings(state, settings) {
    state.exportSettings = settings;
  },

  setScannersService(state, service) {
    state.scannersService = service;
  },
};
