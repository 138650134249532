<template>
  <ContainedModal
    id="detailed-dialog"
    v-model="dialogModel"
    scrollable
    max-width="1440px"
    height="100%"
  >
    <div v-if="isLoading" class="loader-container" @click="dialogModel = false">
      <div class="loader">
        <v-progress-circular
          color="dark-blue"
          indeterminate
          :size="52"
          :width="5"
        ></v-progress-circular>
      </div>
    </div>
    <v-card v-else class="semi-transparent">
      <v-card-title class="main">
        <div class="scanner-select-wrap">
          <span class="title-text">{{ cardTitle }}</span>
          <v-select
            v-if="scannersOnMarker.length > 1"
            class="scanner-selector"
            v-model="selectScannerModel"
            :items="scannersOnMarker"
            :item-title="
              scannersOnMarker.every((scanner) => scanner.tag) ? 'tag' : 'id'
            "
            item-value="id"
            density="compact"
            variant="underlined"
            hide-details
            return-object
          ></v-select>
        </div>
        <div>
          <v-btn
            variant="tonal"
            size="small"
            :color="tab === 'info' ? '#24e3c6' : 'white'"
            prepend-icon="mdi-information-slab-circle-outline"
            @click="tab = 'info'"
          >
            {{
              `${$t("pipe_scanner.info")} / ${$t("pipe_scanner.images.title")}`
            }}
          </v-btn>
          <v-btn
            variant="tonal"
            size="small"
            :color="tab === 'statistics' ? '#24e3c6' : 'white'"
            prepend-icon="mdi-chart-box"
            @click="tab = 'statistics'"
          >
            {{ $t("pipe_scanner.details_modal.statistics") }}
          </v-btn>
          <v-btn
            variant="tonal"
            size="small"
            :color="tab === 'wall_thickness' ? '#24e3c6' : 'white'"
            prepend-icon="mdi-chart-scatter-plot"
            @click="tab = 'wall_thickness'"
          >
            {{ $t("pipe_scanner.details_modal.wall_thickness.title") }}
          </v-btn>
          <v-icon icon="mdi-close" @click="dialogModel = false" />
        </div>
      </v-card-title>
      <v-window v-if="detailed" v-model="tab">
        <v-window-item value="info">
          <v-card-text class="info-container">
            <InfoCard class="info-card" :detailed="detailed" />
            <ScannerGallery class="photos-card" :scanner-id="detailed.id" />
          </v-card-text>
        </v-window-item>
        <v-window-item value="statistics">
          <v-card-text>
            <StatisticsPlotCard :detailed="detailed" />
          </v-card-text>
        </v-window-item>
        <v-window-item value="wall_thickness">
          <v-card-text>
            <WallThicknessCard :detailed="detailed" />
          </v-card-text>
        </v-window-item>
      </v-window>
    </v-card>
  </ContainedModal>
</template>

<script>
import { mapState } from "vuex";
import { getScannerDetailed } from "@/features/pipescanner/api";

import ContainedModal from "@/components/ContainedModal.vue";
import InfoCard from "./InfoCard.vue";
import ScannerGallery from "../ScannerGallery.vue";
import WallThicknessCard from "./WallThicknessCard.vue";
import StatisticsPlotCard from "./StatisticsPlotCard.vue";

export default {
  name: "DetailedModal",

  components: {
    InfoCard,
    ScannerGallery,
    StatisticsPlotCard,
    WallThicknessCard,
    ContainedModal,
  },

  data() {
    return {
      currentScanner: null,
      scannersOnMarker: [],
      open: false,
      tab: "info",
      detailed: null,
      isLoading: true,
      controller: undefined,
    };
  },

  computed: {
    ...mapState("auth", ["selectedGroup"]),

    cardTitle() {
      const title = this.$t("pipe_scanner.details_modal.title");
      if (this.scannersOnMarker.length > 1) {
        return `${title}:`;
      }
      return `${title} ${this.detailed?.tag}`;
    },

    dialogModel: {
      get() {
        return this.open;
      },
      set(value) {
        if (!value && this.controller) {
          this.controller.abort();
        }

        this.open = value;
      },
    },

    selectScannerModel: {
      get() {
        return this.currentScanner;
      },
      set(scanner) {
        this.currentScanner = scanner;
        this.fetchScannerFullDetails(scanner.id);
      },
    },
  },

  methods: {
    showScannerDetailed(id, scannersOnMarker) {
      this.dialogModel = true;
      if (scannersOnMarker) {
        this.scannersOnMarker = scannersOnMarker;
        this.currentScanner = this.scannersOnMarker.find(
          (scanner) => scanner.id === id
        );
      }
      this.fetchScannerFullDetails(id);
    },

    async fetchScannerFullDetails(id) {
      this.isLoading = true;
      this.detailed = null;
      this.controller = new AbortController();
      try {
        const token = await this.$auth0.getAccessTokenSilently();
        const detailed = await getScannerDetailed(
          token,
          this.selectedGroup,
          id,
          this.controller
        );
        if (detailed) {
          this.detailed = this.convertScannerUnits(detailed);
        }
        this.isLoading = false;
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Read scanner detailed - ${error.message}`, {
            position: "top-right",
          });
          this.isLoading = false;
        }
      }
    },

    convertScannerUnits(scanner) {
      const converted = { ...scanner, results: {} };
      [
        "diameter",
        "wallthickness_expected",
        "wallthickness_expected_validation",
        "circumference",
      ].forEach((key) => {
        converted[key] = this.$units.convert(converted[key], "mm");
      });

      Object.keys(scanner.results).forEach((resultKey) => {
        converted.results[resultKey] = { ...scanner.results[resultKey] };

        const convertedMeasure = converted.results[resultKey];
        ["dist", "wt_remaining"].forEach((parametr) => {
          convertedMeasure[parametr] = convertedMeasure[parametr].map((value) =>
            this.$units.convert(value, "mm")
          );
        });
      });

      return converted;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

#detailed-dialog {
  .semi-transparent {
    background: rgba(255, 255, 255, 0.9);
  }
  .loader-container {
    @include flex-center;
    height: 100%;
    .loader {
      @include flex-center;
      height: 150px;
      width: 150px;
      background: white;
      border-radius: 8px;
    }
  }

  .v-window {
    height: 100%;
    overflow-y: auto;
    .info-container {
      display: flex;
      width: 100%;

      .info-card {
        height: fit-content;
        flex-shrink: 0;
      }
      .photos-card {
        margin-left: 1%;
        width: 100%;
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/variables.scss";

#detailed-dialog {
  .v-card {
    .v-card-title {
      &.main {
        color: white;
        font-size: 1.2rem;

        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 2px;
        padding: 4px 20px 10px;
        .v-btn {
          margin-right: 8px;
        }
        .mdi-close:hover {
          color: $cyan-main;
        }

        .scanner-select-wrap {
          display: flex;
          align-items: flex-end;
          flex-grow: 1;
          .title-text {
            margin-top: 4px;
          }
          .scanner-selector {
            padding-bottom: 4px;
            margin-inline: 8px 16px;
            max-width: 270px;
          }
        }
      }
      color: $cyan-main;
      font-size: 1rem;
      padding: 4px 16px;
      background: $black-primary;
    }
  }
}
</style>
