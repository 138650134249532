<template>
  <div style="display: flex">
    <MappingSelectTable
      :onCheck="setSettingsUse"
      :mappingSettings="mappingSettings"
      :isLoading="isLoadingMappingSettings"
      :onSave="saveMappingSettings"
    />
    <v-card :elevation="4" class="distance-plots">
      <OdoDistanceChart
        :isLoading="isLoadingDistanceOdo"
        :odoDistance="odoDistance"
      />
      <AlternativeDistanceChart
        :isLoading="isLoadingDistanceAlternative"
        :alternativeDistance="alternativeDistance"
      />
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MappingSelectTable from "./MappingSelectTable.vue";
import OdoDistanceChart from "./OdoDistanceChart.vue";
import AlternativeDistanceChart from "./AlternativeDistanceChart.vue";
import {
  getMappingSettings,
  postMappingSettings,
  getDistanceOdo,
  getDistanceAlternative,
} from "@/features/analyser/api";

export default {
  name: "MappingTab",
  components: {
    MappingSelectTable,
    OdoDistanceChart,
    AlternativeDistanceChart,
  },
  emits: ["step-complete"],
  data() {
    return {
      isLoadingMappingSettings: false,
      mappingSettings: [],
      isLoadingDistanceOdo: false,
      odoDistance: [],
      isLoadingDistanceAlternative: false,
      alternativeDistance: [],
    };
  },
  mounted() {
    this.fetchMappingSettings();
    this.fetchDistanceOdo();
    this.fetchDistanceAlternative();
  },

  computed: {
    ...mapState("auth", ["selectedGroup"]),
  },

  methods: {
    async fetchMappingSettings() {
      try {
        this.mappingSettings = [];
        this.isLoadingMappingSettings = true;

        const token = await this.$auth0.getAccessTokenSilently();
        const data = await getMappingSettings(
          token,
          this.$route.params.id,
          this.selectedGroup
        );
        this.mappingSettings = data;
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Read mapping settings - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.isLoadingMappingSettings = false;
      }
    },

    async saveMappingSettings() {
      try {
        const token = await this.$auth0.getAccessTokenSilently();
        const response = await postMappingSettings(
          token,
          this.$route.params.id,
          this.selectedGroup,
          this.mappingSettings
        );
        if (response.status === 200) {
          this.$emit("step-complete");
          this.$toast.success(`Mapping settings are saved`, {
            position: "top-right",
          });
        }
      } catch (error) {
        this.$toast.error(`Save mapping sattings - ${error.message}`, {
          position: "top-right",
        });
      }
    },

    async fetchDistanceOdo() {
      try {
        this.odoDistance = [];
        this.isLoadingDistanceOdo = true;

        const token = await this.$auth0.getAccessTokenSilently();
        const data = await getDistanceOdo(
          token,
          this.$route.params.id,
          this.selectedGroup
        );
        this.odoDistance = data;
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Read odo distance - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.isLoadingDistanceOdo = false;
      }
    },

    async fetchDistanceAlternative() {
      try {
        this.alternativeDistance = [];
        this.isLoadingDistanceAlternative = true;

        const token = await this.$auth0.getAccessTokenSilently();
        const data = await getDistanceAlternative(
          token,
          this.$route.params.id,
          this.selectedGroup
        );
        this.alternativeDistance = data;
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Read odo distance - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.isLoadingDistanceAlternative = false;
      }
    },

    setSettingsUse(index) {
      this.mappingSettings[index].use_odo =
        !this.mappingSettings[index].use_odo;
    },
  },
};
</script>

<style lang="scss" scoped>
.distance-plots {
  flex-grow: 1;
  margin-left: 1rem;
  padding: 1rem;
  height: fit-content;
}
</style>
