import { createStore } from "vuex";

import { anomaliesModule } from "@/store/anomalies";
import { jointsModule } from "@/store/joints";
import { pipePartsModule } from "@/store/pipeParts";
import { pipelinesModule } from "@/store/pipelines";
import { safetyFactorModule } from "@/store/safetyFactor";
import { pipeScannersModule } from "@/store/pipeScanners";
import { scannerControlModule } from "@/store/scannerControl";
import { mapModule } from "@/store/map";
import { settingsModule } from "@/store/settings";
import { authModule } from "@/store/auth";

export default createStore({
  modules: {
    anomalies: anomaliesModule,
    joints: jointsModule,
    pipeParts: pipePartsModule,
    pipelines: pipelinesModule,
    safetyFactor: safetyFactorModule,
    pipeScanners: pipeScannersModule,
    scannerControl: scannerControlModule,
    settings: settingsModule,
    map: mapModule,
    auth: authModule,
  },

  mutations: {
    changeFilters(state, payload) {
      const { key, filter, value } = payload;
      state[key][filter] = value;
    },
    _resetFilters(state) {
      state.jointFilters = {
        selectedJointsTypes: [],
        selectedMaterials: [],
        selectedVerticalAngle: 0,
        selectedHorizontalAngle: 0,
        selectedGapMean: [],
        selectedGapMax: [],
        plotlyFilter: {
          property: "",
          range: [],
        },
      };
      state.pipePartsFilters = {
        selectedDistance: [],
        selectedPipePartsTypes: [],
        selectedMaterials: [],
        selectedOvalityMax: [],
        selectedOvalityMean: [],
        selectedDeformationHor: [],
        selectedDeformationVer: [],
        plotlyFilter: {
          property: "",
          range: [],
        },
      };
      state.safetyFactorFilters = {
        selectedMainUncertainties: [],
        selectedSafetyRange: [],
        selectedLowerSafetyRange: [],
        selectedUpperSafetyRange: [],
        plotlyFilter: {
          property: "",
          range: [],
        },
      };
    },
  },

  actions: {
    clearSelectedPipeData({ commit }) {
      commit("pipelines/setSelectedPipelineId", null);
      commit("pipelines/setSelectedInspectionId", null);
      commit("pipelines/setSelectedInspectionStats", null);
      commit("pipelines/setComments", []);
      commit("pipelines/setPipelineElevation", []);
    },

    clearAllSelectedData({ commit, dispatch }) {
      dispatch("clearSelectedPipeData");
      commit("pipelines/setGroupPipelines", []);
      commit("pipeScanners/resetScanners");
      commit("scannerControl/resetState", null, { root: true });
    },

    resetFilters({ commit, dispatch }) {
      commit("_resetFilters");
      dispatch("resetAnomaliesFilters");
    },
  },
});
