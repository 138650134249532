<template>
  <v-select
    :label="$t('anomalies.filters.all_types')"
    :model-value="anomalyFilters.selectedAnomaliesTypes"
    @update:modelValue="
      (value) => setFilters({ selectedAnomaliesTypes: value })
    "
    :items="anomaliesTypesOptions"
    multiple
    variant="underlined"
  ></v-select>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("anomalies", ["anomalyFilters"]),
    ...mapGetters("anomalies", ["anomaliesTypes"]),

    anomaliesTypesOptions() {
      return this.anomaliesTypes.map((type) => ({
        value: type,
        title: this.$t(`anomalies.types.${type}`),
      }));
    },
  },
  methods: {
    ...mapMutations("anomalies", ["setFilters"]),
  },
};
</script>
