import { pipelinesState } from "@/store/pipelines/state";
import { pipelinesGetters } from "@/store/pipelines/getters";
import { pipelinesMutations } from "@/store/pipelines/mutations";
import { pipelinesActions } from "@/store/pipelines/actions";

export const pipelinesModule = {
  namespaced: true,
  state: pipelinesState,
  getters: pipelinesGetters,
  mutations: pipelinesMutations,
  actions: pipelinesActions,
};
