<template>
  <div v-if="selectedPipeline" class="container">
    <v-card rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("pipe_parts.table_name") }}
        <i>
          {{ inspectionTitle }}
        </i>
      </v-card-title>
      <v-card-text :class="[isLoading ? '' : 'pt-6px']">
        <div v-if="isLoading" class="text-center">
          <v-progress-circular
            color="dark-blue"
            indeterminate
            :size="52"
            :width="5"
          ></v-progress-circular>
        </div>
        <div v-else>
          <div class="filters-containers">
            <div class="filter-selectors">
              <PipePartsTypeSelector :items="typeOptions" hide-details />
              <PipePartsMaterialsSelector
                :items="materialOptions"
                hide-details
              />
            </div>
            <div class="filter-sliders">
              <PipePartsDistanceSlider class="slider" />
              <PipePartsOvalityMeanSlider class="slider" />
              <PipePartsOvalityMaxSlider class="slider" />
              <PipePartsAxialDeformHorSlider class="slider" />
              <PipePartsAxialDeformVerSlider class="slider" />
            </div>
          </div>
          <PipePartsTable
            :wallThicknessRemaining="wallThicknessRemaining"
            :onChangeWTRemainingLayers="onChangeWTRemainingLayers"
          />
        </div>
      </v-card-text>
    </v-card>

    <v-card v-if="!isLoading" rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("pipe_parts.details.title") }}
      </v-card-title>
      <v-card-text>
        <p class="text-h6">{{ $t("pipe_parts.details.map") }}</p>
        <PipePartsMap
          :gradientColor="gradientColor"
          :gradientOptions="gradientOptions"
          :pipePartsMapData="pipePartsMapData"
          :legendTitle="histogramTitle"
        />
        <v-divider color="black" :thickness="2"></v-divider>
        <div class="d-flex">
          <PipePartsHistogram :title="histogramTitle" />
          <PipePartsRadar :title="radarTitle" />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  PipePartsTable,
  PipePartsHistogram,
  PipePartsRadar,
} from "@/pages/pipe-parts/components";
import {
  PipePartsDistanceSlider,
  PipePartsOvalityMeanSlider,
  PipePartsMap,
  PipePartsOvalityMaxSlider,
  PipePartsAxialDeformHorSlider,
  PipePartsAxialDeformVerSlider,
  PipePartsTypeSelector,
  PipePartsMaterialsSelector,
} from "./components";

export default {
  name: "PipeParts",
  props: {
    wallThicknessRemaining: Object,
    onChangeWTRemainingLayers: Function,

    gradientOptions: Array,
    gradientColor: Array,

    pipePartsMapData: Array,

    histogramTitle: String,
    radarTitle: String,

    typeOptions: Array,
    materialOptions: Array,
  },

  components: {
    PipePartsTable,
    PipePartsMap,
    PipePartsHistogram,
    PipePartsRadar,
    PipePartsDistanceSlider,
    PipePartsOvalityMeanSlider,
    PipePartsOvalityMaxSlider,
    PipePartsAxialDeformHorSlider,
    PipePartsAxialDeformVerSlider,
    PipePartsTypeSelector,
    PipePartsMaterialsSelector,
  },

  computed: {
    ...mapState("pipelines", ["selectedInspectionId"]),

    ...mapGetters("pipelines", ["selectedPipeline"]),

    ...mapState("pipeParts", ["isLoading"]),

    inspectionTitle() {
      if (!this.selectedPipeline) return "";
      const inspectionName = this.selectedPipeline.inspections.find(
        (ins) => ins.id === this.selectedInspectionId
      )?.project_reference;
      return `- ${this.selectedPipeline.client_pipeline_id} : ${this.selectedPipeline.pipeline_name} - ${inspectionName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
  & > div:first-child {
    width: calc(100% - 400px);
  }
}
.pt-6px {
  padding-top: 6px !important;
}

.filters-containers {
  .filter-selectors {
    display: flex;
    .v-select {
      padding: 0 1rem;
      width: 50%;
    }
  }
  .filter-sliders {
    display: flex;
    text-align: center;
    margin: 1rem 0;
    .slider {
      width: 20%;
    }
  }
}
</style>
