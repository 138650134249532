import { OpenAPI } from "@/open-api-code/ili-api";
import jwt_decode from "jwt-decode";
import { useToast } from "vue-toast-notification";
import { auth0 } from "@/config/auth0";
import { getGroupAvailableServices } from "@/layouts/MainLayout/client.api";
import {
  INLINE_INSPECTIONS,
  PIPESCANNER_INSPECTIONS,
} from "@/features/administration/constants";

const toast = useToast();
const requests = new Map();

export const authActions = {
  setUserGroups({ commit }, groups) {
    commit("setServiceGroups", groups);
    commit("setSelectedGroup", groups[0]);
  },
  async fetchPermissionsAndGroups({ commit, dispatch }) {
    const token = await auth0.getAccessTokenSilently();
    if (token) {
      OpenAPI.TOKEN = token;
      const payload = jwt_decode(token);
      commit("setUserPermissions", payload.permissions);
      const groupKey = "https://acqgroups.cloud/groups";
      if (Object.prototype.hasOwnProperty.call(payload, groupKey)) {
        dispatch("setUserGroups", payload[groupKey]);
        dispatch("getGroupData");
      }
    } else {
      console.log("No token, try to log in!");
    }
  },
  async getGroupData({ commit, dispatch, state, rootState }) {
    try {
      if (requests.has("availableServicesRequest")) {
        requests.get("availableServicesRequest").abort();
      }
      if (requests.has("pipelinesRequest")) {
        requests.get("pipelinesRequest").cancel();
      }
      if (requests.has("scannersRequest")) {
        requests.get("scannersRequest").abort();
      }

      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();
      const services = await getGroupAvailableServices(
        token,
        state.selectedGroup,
        controller
      );
      const pipesAccess = services.some(
        ({ service }) => service === INLINE_INSPECTIONS
      );

      requests.set("availableServicesRequest", controller);

      if (pipesAccess) {
        dispatch("pipelines/getPipelines", undefined, { root: true });
      } else {
        commit("pipelines/setIsLoadingPipelines", false, { root: true });
      }

      const scannersServiceToSet = services.find(
        ({ service }) => service === PIPESCANNER_INSPECTIONS
      );
      commit("scannerControl/setScannersService", scannersServiceToSet, {
        root: true,
      });
      if (scannersServiceToSet) {
        commit(
          "scannerControl/setExportSettings",
          scannersServiceToSet.exportSettings,
          { root: true }
        );
        if (rootState.map.showMapScanners) {
          commit("pipeScanners/fetchPipeScanners", undefined, { root: true });
        }
      }
    } catch (error) {
      if (error.name !== "CanceledError") {
        toast.error(`Read services - ${error.message}`, {
          position: "top-right",
        });
        commit("pipelines/setIsLoadingPipelines", false, { root: true });
      }
    }
  },
};
