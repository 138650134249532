<template>
  <v-card color="#ffecb3" style="min-width: 150px">
    <v-card-text style="padding: 16px 22px 16px 16px">
      <span>{{ comment.comment }}</span>
    </v-card-text>
    <v-card-actions v-if="isAuthor">
      <v-btn
        :loading="isDeleting"
        class="text-none"
        size="small"
        color="red"
        style="margin-right: 6px"
        variant="outlined"
        @click="deleteComment"
        >Delete</v-btn
      >
      <v-btn
        :disabled="isDeleting"
        class="text-none"
        size="small"
        color="#181f33"
        @click="$emit('edit')"
        variant="tonal"
        >Edit</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import jwt_decode from "jwt-decode";

import { userCommentsAPI } from "@/features/comments/api";

export default {
  name: "CommentPopup",
  emits: ["edit", "deleted"],
  props: {
    comment: Object,
  },
  data() {
    return {
      isAuthor: false,
      isDeleting: false,
    };
  },

  async mounted() {
    const userId = await this.getUserId();

    this.isAuthor = userId === this.comment.user_id;
  },

  computed: {
    ...mapState("auth", ["selectedGroup"]),
  },

  methods: {
    ...mapMutations("pipelines", ["removeComment"]),

    async getUserId() {
      const token = await this.$auth0.getAccessTokenSilently();
      const payload = jwt_decode(token);
      return payload?.sub;
    },

    async deleteComment() {
      try {
        this.isDeleting = true;
        const response = await userCommentsAPI.remove(
          this.comment.id,
          this.selectedGroup
        );
        if (response.status === 200) {
          this.$emit("deleted");
          this.removeComment(this.comment.id);
        }
      } catch (error) {
        console.log(error);
        this.$toast.error(`Deleting comment - ${error.message}`, {
          position: "top-right",
        });
      } finally {
        this.isDeleting = false;
      }
    },
  },
};
</script>
