<template>
  <ContainedModal
    id="edit-dialog"
    v-model="dialogModel"
    max-width="800"
    max-height="820"
    height="100%"
  >
    <div v-if="isLoading" class="loader-container" @click="dialogModel = false">
      <div class="loader">
        <v-progress-circular
          color="dark-blue"
          indeterminate
          :size="52"
          :width="5"
        ></v-progress-circular>
      </div>
    </div>
    <v-card v-else class="semi-transparent">
      <v-card-title class="main-title">
        <span class="main-title-text">{{ cardTitle }}</span>
        <v-select
          v-if="scannersOnMarker.length > 1"
          v-model="selectScannerModel"
          :items="scannersOnMarker"
          :item-title="
            scannersOnMarker.every((scanner) => scanner.tag) ? 'tag' : 'id'
          "
          item-value="id"
          density="compact"
          variant="underlined"
          hide-details
          return-object
          style="padding-bottom: 4px; margin-inline: 8px 16px"
        ></v-select>
        <div>
          <v-btn
            variant="tonal"
            size="small"
            :color="tab === 'details' ? '#24e3c6' : 'white'"
            prepend-icon="mdi-archive-edit-outline"
            @click="tab = 'details'"
          >
            {{ $t("pipe_scanner.edit_modal.tabs.details") }}
          </v-btn>
          <v-btn
            variant="tonal"
            size="small"
            :color="tab === 'images' ? '#24e3c6' : 'white'"
            prepend-icon="mdi-image-multiple"
            @click="tab = 'images'"
          >
            {{ $t("pipe_scanner.images.title") }}
          </v-btn>
          <v-btn
            variant="tonal"
            size="small"
            :color="tab === 'location' ? '#24e3c6' : 'white'"
            prepend-icon="mdi-map-marker"
            @click="tab = 'location'"
          >
            {{ $t("location.title") }}
          </v-btn>
          <v-icon icon="mdi-close" @click="dialogModel = false" />
        </div>
      </v-card-title>
      <v-window v-if="detailed" v-model="tab">
        <v-window-item value="details">
          <v-card-text
            style="display: flex; justify-content: center; padding-bottom: 2rem"
          >
            <FormCard :detailed="detailed" :scannerStatus="scannerStatus" />
          </v-card-text>
        </v-window-item>
        <v-window-item value="images">
          <v-card-text>
            <ScannerGallery :scanner-id="detailed.id" editMode />
          </v-card-text>
        </v-window-item>
        <v-window-item value="location">
          <v-card-text>
            <LocationUpdate
              v-model:latitude="detailed.latitude"
              v-model:longitude="detailed.longitude"
            />
            <div
              style="display: flex; justify-content: flex-end; padding: 0.6rem"
            >
              <v-alert
                v-if="locationAlert.type"
                :type="locationAlert.type"
                :text="locationAlert.text"
                closable
              ></v-alert>
              <v-btn
                :loading="isSavingLocation"
                color="#1c243d"
                @click="saveLocation"
              >
                {{ $t("common.save") }}
              </v-btn>
            </div>
          </v-card-text>
        </v-window-item>
      </v-window>
    </v-card>
  </ContainedModal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import {
  getScannerDetailed,
  postScannerInspection,
} from "@/features/pipescanner/api";

import FormCard from "./FormCard.vue";
import ScannerGallery from "../ScannerGallery.vue";
import { LocationUpdate } from "@/components";
import ContainedModal from "@/components/ContainedModal.vue";

export default {
  name: "EditModal",

  components: { FormCard, LocationUpdate, ScannerGallery, ContainedModal },

  data() {
    return {
      currentScanner: null,
      scannersOnMarker: [],
      open: false,
      detailed: null,
      isLoading: true,
      requests: new Map(),
      scannerStatus: null,
      tab: "details",

      locationAlert: { type: null, text: "" },
      isSavingLocation: false,
    };
  },

  computed: {
    ...mapState("auth", ["selectedGroup"]),

    cardTitle() {
      const title = this.$t("pipe_scanner.edit_modal.title");
      if (this.scannersOnMarker.length <= 1 && this.detailed.tag) {
        return `${title}: ${this.detailed.tag}`;
      }
      if (this.scannersOnMarker.length > 1) {
        return `${title}:`;
      }
      return title;
    },

    dialogModel: {
      get() {
        return this.open;
      },
      set(value) {
        if (!value && this.requests.has("detailedRequest")) {
          this.requests.get("detailedRequest").abort();
        }

        this.open = value;
      },
    },

    selectScannerModel: {
      get() {
        return this.currentScanner;
      },
      set(scanner) {
        this.currentScanner = scanner;
        this.fetchScannerFullDetails(scanner.id);
      },
    },
  },

  methods: {
    ...mapMutations("pipeScanners", ["updateScanner"]),

    showScannerEditor(id, status, scannersOnMarker) {
      this.dialogModel = true;
      this.scannerStatus = status;
      if (scannersOnMarker) {
        this.scannersOnMarker = scannersOnMarker;
        this.currentScanner = this.scannersOnMarker[0];
      }
      this.fetchScannerFullDetails(id);
    },

    async fetchScannerFullDetails(id) {
      this.isLoading = true;
      this.detailed = null;
      try {
        const token = await this.$auth0.getAccessTokenSilently();
        const controller = new AbortController();
        this.requests.set("detailedRequest", controller);
        const detailed = await getScannerDetailed(
          token,
          this.selectedGroup,
          id,
          controller
        );
        this.detailed = detailed;
        this.isLoading = false;
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Read scanner detailed - ${error.message}`, {
            position: "top-right",
          });
          this.isLoading = false;
        }
      }
    },

    async saveLocation() {
      try {
        this.isSavingLocation = true;
        this.locationAlert = { type: null, text: "" };
        const location = {
          longitude: this.detailed.longitude,
          latitude: this.detailed.latitude,
        };

        const token = await this.$auth0.getAccessTokenSilently();
        await postScannerInspection(
          token,
          this.selectedGroup,
          this.detailed.id,
          location
        );

        this.updateScanner({
          key: this.scannerStatus,
          scannerId: this.detailed.id,
          updateForm: location,
        });

        this.isSavingLocation = false;
        this.locationAlert = {
          type: "success",
          text: this.$t("location.success_message"),
        };
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.locationAlert = {
            type: "error",
            text: error.response.data?.detail[0],
          };
          this.isSavingLocation = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

#edit-dialog {
  max-width: 1440px;

  .semi-transparent {
    background: rgba(255, 255, 255, 0.9);
  }
  .loader-container {
    @include flex-center;
    height: 100%;
    .loader {
      @include flex-center;
      height: 150px;
      width: 150px;
      background: white;
      border-radius: 8px;
    }
  }

  .v-window {
    height: 100%;
    overflow-y: auto;
  }

  .v-alert {
    padding: 4px;
    margin-right: 1rem;
  }
}
</style>

<style lang="scss">
@import "@/assets/scss/variables.scss";

#edit-dialog {
  .v-card {
    height: 100%;

    .v-card-title {
      color: $cyan-main;
      font-size: 1rem;
      padding: 4px 16px;
      background: $black-primary;

      &.main-title {
        color: white;
        font-size: 1.2rem;
        padding: 8px 20px 12px;
        margin-bottom: 2px;

        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .main-title-text {
          margin-top: 6px;
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .v-btn {
          margin-right: 8px;
        }

        .mdi-close {
          margin-bottom: 2px;
          &:hover {
            color: $cyan-main;
          }
        }
      }
    }
  }
}
</style>
