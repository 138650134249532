<template>
  <PlotlyGraph
    :data="plotlyData"
    :layout="plotlyLayout"
    :config="{ displayModeBar: false }"
    @click.self="handleChartClick"
    @mousedown.stop="$emit('graph-mousedown')"
  />
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

import { PlotlyGraph } from "@/components";

import { getUncertaintiesWithColors } from "@/colorLegends.js";

export default {
  name: "UncertaintyContributionsChart",

  emits: ["graph-mousedown"],

  props: ["visibleParts"],

  components: {
    PlotlyGraph,
  },

  computed: {
    ...mapState("pipelines", ["selectedInspectionId"]),
    ...mapState("map", ["isMapMode"]),

    ...mapGetters("pipelines", ["selectedPipeline"]),

    ...mapState("pipeParts", ["pipeParts"]),

    plotTitle() {
      const inspectionName = this.selectedPipeline.inspections.find(
        (ins) => ins.id === this.selectedInspectionId
      )?.project_reference;

      return `${this.selectedPipeline.client_pipeline_id} : ${this.selectedPipeline.pipeline_name} - ${inspectionName}`;
    },

    uncertaintiesWithColors() {
      return getUncertaintiesWithColors(this.$t);
    },

    plotlyData() {
      const data = [];
      for (const uncertaintyKey in this.uncertaintiesWithColors) {
        if (uncertaintyKey === "none") continue;
        const { text, color } = this.uncertaintiesWithColors[uncertaintyKey];
        const uncertaintyApiName = uncertaintyKey + "_uncertainty_contribution";
        data.push({
          y: this.pipeParts.map((part) => {
            if (part.safety_factor_calculation) {
              return part.safety_factor_calculation[uncertaintyApiName];
            }
            return;
          }),
          name: text,
          marker: {
            color,
            line: {
              color: "white",
              width: 0.5,
            },
          },
          type: "bar",
        });
      }

      return data;
    },

    plotlyLayout() {
      const layout = {
        bargap: 0.1,
        barmode: "stack",
        legend: { traceorder: "reversed" },
        xaxis: {
          title: this.isMapMode && this.plotTitle,
          showgrid: false,
        },
        margin: {
          t: 20,
          b: 70,
          l: 40,
          r: 50,
        },
      };

      if (this.visibleParts?.length) {
        const first = this.visibleParts[0].order_index;
        const last = this.visibleParts.at(-1).order_index;
        layout.xaxis["range"] = [first - 0.5, last + 0.5];
      }

      return layout;
    },
  },

  methods: {
    ...mapMutations("safetyFactor", ["setSelectedPipePartId"]),

    handleChartClick(e) {
      const index = e.points[0].x;
      this.setSelectedPipePartId(this.pipeParts[index].id);
    },
  },
};
</script>
