import { INITIAL_PLOTLY_FILTER } from "@/store/safetyFactor/state";

const requests = new Map();

export const safetyFactorActions = {
  async initSafetyFactor({ dispatch, commit }) {
    commit("setLoading", true);
    await dispatch("pipeParts/getPipeParts", undefined, { root: true });
    dispatch("initFilters");

    requests.delete("pipePartsRequest");

    commit("setLoading", false);
  },

  // FIXME may be it's better to rename because of it's duplicate of mutation name
  setSelectedViewFactor({ commit }, viewFactor) {
    commit("setSelectedLegend", null);
    commit("setSelectedViewFactor", viewFactor);
  },

  // TODO check if it is duplicate of joints action
  toggleSelectedLegend({ commit, state }, legend) {
    if (state.selectedLegend === legend) {
      commit("setSelectedLegend", null);
      return;
    }

    commit("setSelectedLegend", legend);
  },

  initFilters({ commit, getters }) {
    commit("setFilters", {
      selectedSafetyRange: getters.minMaxSafetyRange,
      selectedLowerSafetyRange: getters.minMaxLowerSafetyRange,
      selectedUpperSafetyRange: getters.minMaxUpperSafetyRange,
    });
  },

  cancelAllRequests() {
    for (const request of requests.values()) {
      request.cancel();
    }
  },

  // TODO check if it is duplicate of pipeParts action
  resetPlotlyFilter({ commit }) {
    commit("setFilters", { plotlyFilter: INITIAL_PLOTLY_FILTER });
  },
};
