<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      ref="modifiedMapRef"
      :showLoader="isLoadingAnomalies"
      :wmsLayers="wmsLayers"
      forceHideScanners
      @mb-moveend="onMoveend"
      @click="selectedWidget = null"
    >
      <template v-if="selectedAnomaly">
        <MapboxMarker
          :lng-lat="selectedAnomaly.geom.coordinates[0]"
          :scale="0.9"
          color="orange"
        >
        </MapboxMarker>
        <MapboxPopup :lng-lat="selectedAnomaly.geom.coordinates[0]">
          <MapInfoPopup :data="selectedAnomaly" type="anomaly" />
        </MapboxPopup>
      </template>

      <template v-slot:sources="{ openDigupMenu }">
        <template
          v-for="{ id, sourceOptions, layerOptions } in anomalyMapData"
          :key="id"
        >
          <MapboxSource :id="id" :options="sourceOptions" />
          <MapboxLayer
            :id="layerOptions.id"
            :options="layerOptions"
            @mb-mouseenter="onLayerMouseEnter"
            @mb-mouseleave="onLayerMouseLeave"
            @mb-click="onLayerClick"
            @mb-contextmenu="
              (event) => onLayerContextmenu(event, openDigupMenu)
            "
          />
        </template>
      </template>

      <template v-slot:append>
        <WidgetsList
          :widgets="widgets"
          :widgetsBarConfig="widgetsBarConfig"
          v-if="!isLoadingAnomalies"
        >
          <template v-slot:[`${AnomalyWidget.TypesLegend}-content`]>
            <AnomaliesLegend />
          </template>

          <template v-slot:[`${AnomalyWidget.WmsWidget}-content`]>
            <WMSWidget v-model:wmsLayers="wmsLayers" />
          </template>

          <template v-slot:[`${AnomalyWidget.Table}-title-prepend`]>
            <FilterPopupMenu v-model:show="showFilter" />
          </template>
          <template v-slot:[`${AnomalyWidget.Table}-content`]>
            <AnomaliesTable />
          </template>

          <template v-slot:[`${AnomalyWidget.HeightProfileChart}-content`]>
            <AnomaliesHeightChart
              :visibleDistance="visibleDistance"
              @graph-mousedown="selectedWidget = 'heightProfile'"
            />
          </template>

          <template v-slot:[`${AnomalyWidget.Cylinder}-content`]>
            <AnomaliesCylinder />
          </template>

          <template v-slot:[`${AnomalyWidget.WmsLegend}-content`]>
            <WMSLegend :wmsLayers="wmsLayers" :currentZoom="currentZoom" />
          </template>
        </WidgetsList>
      </template>
    </ModifiedMapbox>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

import {
  MapboxMarker,
  MapboxPopup,
  MapboxLayer,
  MapboxSource,
} from "@studiometa/vue-mapbox-gl";
import {
  AnomaliesHeightChart,
  AnomaliesCylinder,
  AnomaliesTable,
  AnomaliesLegend,
} from "@/pages/anomaly/components";
import { MapInfoPopup, ModifiedMapbox } from "@/components";
import { WMSLegend, WMSWidget } from "@/features/wms/components";
import { FilterPopupMenu } from "./components";
import { WidgetsList } from "@/components/map-componets/widgets";

import { AnomalyWidget } from "@/pages/anomaly/views/AnomaliesMapmode/config";

export default {
  name: "AnomaliesMapmode",

  components: {
    MapboxMarker,
    MapboxPopup,
    MapboxLayer,
    MapboxSource,
    ModifiedMapbox,
    MapInfoPopup,
    WidgetsList,
    AnomaliesCylinder,
    AnomaliesHeightChart,
    FilterPopupMenu,
    AnomaliesLegend,
    AnomaliesTable,
    WMSLegend,
    WMSWidget,
  },

  setup() {
    return {
      AnomalyWidget,
    };
  },

  data() {
    return {
      visibleDistance: [],
      wmsLayers: [],
      showFilter: false,
      currentZoom: null,
    };
  },

  computed: {
    widgets() {
      return {
        [AnomalyWidget.TypesLegend]: {
          key: AnomalyWidget.TypesLegend,
          title: this.$t("anomalies.details.anomaly_type"),
          initialWidth: 200,
          initialHeight: "auto",
          getInitialX: () => 5,
          getInitialY: () => 50,
          initiallyMinimized: false,

          displayConfig: {
            isMinimizable: false,
            isResizable: false,
            isDraggable: false,
          },
        },

        [AnomalyWidget.WmsWidget]: {
          key: AnomalyWidget.WmsWidget,
          title: this.$t("dashboard.widgets.color.maximize_title"),
          initialWidth: 350,
          initialHeight: 100,
          getInitialX: (container, widgets) =>
            (container.offsetWidth -
              widgets[AnomalyWidget.WmsWidget].initialWidth) /
            2,
          getInitialY: () => 3,
          initiallyMinimized: false,

          displayConfig: {
            isResizable: false,
          },
        },

        [AnomalyWidget.Table]: {
          key: AnomalyWidget.Table,
          title: this.$t("anomalies.widgets.table.maximize_title"),
          initialWidth: 600,
          initialHeight: 385,
          getInitialX: (container, widgets) =>
            container.offsetWidth -
            widgets[AnomalyWidget.Table].initialWidth -
            5,
          getInitialY: () => 100,
          initiallyMinimized: false,
        },

        [AnomalyWidget.HeightProfileChart]: {
          key: AnomalyWidget.HeightProfileChart,
          title: this.$t("dashboard.widgets.heightProfile.maximize_title"),
          initialWidth: 700,
          initialHeight: 400,
          getInitialX: () => 5,
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            widgets[AnomalyWidget.HeightProfileChart].initialHeight -
            5,
          initiallyMinimized: true,
        },

        [AnomalyWidget.Cylinder]: {
          key: AnomalyWidget.Cylinder,
          title: this.$t("anomalies.details.cylinder.title"),
          initialWidth: 500,
          initialHeight: 500,
          getInitialX: (container, widgets) =>
            container.offsetHeight -
            widgets[AnomalyWidget.Cylinder].initialHeight -
            5,
          getInitialY: (container, widgets) =>
            container.offsetWidth -
            widgets[AnomalyWidget.Cylinder].initialWidth -
            5,
          initiallyMinimized: true,
        },

        [AnomalyWidget.WmsLegend]: {
          key: AnomalyWidget.WmsLegend,
          title: "wms_layers.layers",
          initialWidth: 250,
          initialHeight: 365,
          getInitialX: () => 5,
          getInitialY: () => 180,
          initiallyMinimized: false,
          hidden: this.wmsLayers.length === 0,
        },
      };
    },

    // TODO: design a better system of decorators for widget tabs
    widgetsBarConfig() {
      return {
        [AnomalyWidget.Table]: {
          showFiltersIndicator: this.hasActiveFilters,
        },
      };
    },

    ...mapState("map", ["mapStyle"]),

    ...mapState("anomalies", [
      "checkedAnomaliesSelector",
      "isLoadingAnomalies",
    ]),

    ...mapGetters("anomalies", [
      "selectedAnomaly",
      "anomalyMapData",
      "hasActiveFilters",
    ]),

    map() {
      return this.$refs.modifiedMapRef.map;
    },
  },

  methods: {
    ...mapActions("anomalies", ["toggleSelectedAnomaly"]),

    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
      if (map.getLayer("layer_distance_points")) {
        this.visibleDistance = map
          .queryRenderedFeatures({ layers: ["layer_distance_points"] })
          .map((l) => l.properties.distance);
      }
    },

    onLayerContextmenu({ features }, callback) {
      const { source, properties } = features[0];
      if (source.includes("anomalies") && properties.isDigupSelected) {
        callback();
      }
    },

    onLayerClick({ features, originalEvent }) {
      const { ctrlKey, shiftKey } = originalEvent;

      if (features[0].source.includes("anomalies")) {
        const selectedId = features[0].properties.id;
        if (ctrlKey || shiftKey) {
          this.checkedAnomaliesSelector.set([selectedId]);
        } else {
          this.toggleSelectedAnomaly(null);
          Promise.resolve().then(() => this.toggleSelectedAnomaly(selectedId));
        }
      }
    },

    onLayerMouseEnter({ features, target }) {
      if (features && features[0].source.includes("anomalies")) {
        target.getCanvas().style.cursor = "pointer";
        this.map.boxZoom.disable();
      }
    },

    onLayerMouseLeave({ target }) {
      target.getCanvas().style.cursor = "";
      this.map.boxZoom.enable();
    },
  },

  watch: {
    selectedAnomaly(anomaly) {
      if (anomaly) {
        this.map.flyTo({
          center: anomaly.geom.coordinates[0],
          zoom: this.currentZoom < 17 ? 17 : this.currentZoom,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
