<template>
  <v-select
    :label="$t('joints.filters.materials')"
    :model-value="jointFilters.selectedMaterials"
    @update:modelValue="(value) => setFilters({ selectedMaterials: value })"
    :items="materialOptions"
    multiple
    variant="underlined"
  >
  </v-select>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("joints", ["jointFilters"]),
    ...mapGetters("joints", ["materials"]),

    materialOptions() {
      return this.materials.map((material) => ({
        value: material,
        title: this.$t(`materials.${material}`),
      }));
    },
  },
  methods: {
    ...mapMutations("joints", ["setFilters"]),
  },
};
</script>
