<template>
  <PlotlyGraph
    :data="plotlyData"
    :layout="plotlyLayout"
    :config="{ displayModeBar: false }"
    @mousedown.stop="$emit('graph-mousedown')"
  />
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { PlotlyGraph } from "@/components";

import { anomalyTypeColors } from "@/colorLegends.js";

export default {
  name: "PipePartsHeightChart",

  props: ["visibleParts"],

  components: {
    PlotlyGraph,
  },

  emits: ["graph-mousedown"],

  computed: {
    ...mapState("pipelines", ["pipelineElevation", "selectedInspectionStats"]),
    ...mapState("pipeParts", ["showAnomalies"]),

    ...mapGetters("pipelines", [
      "convertedHeightProfileDistance",
      "convertedHeightProfileZ",
    ]),
    ...mapGetters("pipelines", [
      "heightRange",
      "convertedPipelineElevation",
      "convertedPipelineDepth",
      "pipelineDepth",
    ]),

    ...mapState("anomalies", ["anomalies"]),

    ...mapGetters("pipeParts", ["selectedPipePart"]),

    heightRangeWithAnomalies() {
      if (!this.anomalies) {
        return this.heightRange;
      }

      const zAnomalies = this.anomalies.map(({ z_coordinate }) =>
        this.$units.convert(z_coordinate, "m")
      );

      const [minHeight, maxHeight] = this.heightRange;
      const newMin = Math.min(minHeight, ...zAnomalies);
      const newMax = Math.max(maxHeight, ...zAnomalies);

      return [newMin, newMax];
    },

    chartHeightRange() {
      const [minHeight, maxHeight] = this.heightRangeWithAnomalies;
      return [Math.trunc(minHeight - 5), Math.trunc(maxHeight + 2)];
    },

    plotlyData() {
      const data = [];

      if (this.selectedInspectionStats) {
        if (this.convertedPipelineElevation.length) {
          data.push({
            x: this.convertedHeightProfileDistance,
            y: this.convertedPipelineElevation,
            type: "scatter",
            name: this.$t("dashboard.pipelines.height_profile.elevation"),
            line: {
              color: "#6e4f00",
            },
          });
        }

        data.push({
          x: this.convertedHeightProfileDistance,
          y: this.convertedHeightProfileZ,
          type: "scatter",
          name: "profile",
          line: {
            color: "#1c243d",
          },
        });

        if (this.convertedPipelineDepth.length) {
          data.push({
            x: this.convertedHeightProfileDistance,
            y: this.convertedPipelineDepth,
            type: "scatter",
            fill: "tonexty",
            fillcolor: "#f78b8b",
            name: this.$t("dashboard.pipelines.height_profile.depth"),
            mode: "none",
          });
        }

        if (this.showAnomalies && this.anomalies.length) {
          const showLegendArr = [];
          this.anomalies.forEach((an) => {
            let showlegend = false;

            if (!showLegendArr.includes(an.anomaly_type)) {
              showLegendArr.push(an.anomaly_type);
              showlegend = true;
            }

            data.push({
              x: [
                this.$units.convert(an.distance, "m"),
                this.$units.convert(an.distance, "m"),
              ],
              y: this.chartHeightRange,
              fill: "tozeroy",
              type: "scatter",
              mode: "none",
              fillcolor: anomalyTypeColors[an.anomaly_type],
              line: {
                color: anomalyTypeColors[an.anomaly_type],
              },
              name: this.$t(`anomalies.types.${an.anomaly_type}`),
              legendgroup: an.anomaly_type,
              opacity: 1.0,
              fillopacity: 1.0,
              showlegend,
            });

            data.push({
              x: [
                this.$units.convert(an.distance + an.length, "m"),
                this.$units.convert(an.distance + an.length, "m"),
              ],
              y: this.chartHeightRange,
              fill: "tonexty",
              type: "scatter",
              name: an.anomaly_type,
              legendgroup: an.anomaly_type,
              fillcolor: anomalyTypeColors[an.anomaly_type],
              line: {
                color: anomalyTypeColors[an.anomaly_type],
              },
              opacity: 1.0,
              fillopacity: 1.0,
              mode: "none",
              showlegend: false,
            });
          });
        }

        if (this.selectedPipePart) {
          let showlegend = true;
          data.push({
            x: [this.selectedPipePart.distance, this.selectedPipePart.distance],
            y: this.chartHeightRange,
            fill: "tozeroy",
            type: "scatter",
            mode: "none",
            fillcolor: "#6b0b0b",
            line: {
              color: "#6b0b0b",
            },
            name: this.$t("pipe_parts.details.plotly.pipe_part"),
            legendgroup: "PipePart",
            opacity: 1.0,
            fillopacity: 1.0,
            showlegend,
          });

          showlegend = false;

          data.push({
            x: [
              this.selectedPipePart.distance + 1,
              this.selectedPipePart.distance + 1,
            ],
            y: this.chartHeightRange,
            fill: "tonexty",
            type: "scatter",
            name: this.$t("pipe_parts.details.plotly.pipe_part"),
            legendgroup: "PipePart",
            fillcolor: "#6b0b0b",
            line: {
              color: "#6b0b0b",
            },
            opacity: 1.0,
            fillopacity: 1.0,
            mode: "none",
            showlegend,
          });
        }
      }

      return data;
    },

    plotlyLayout() {
      const dupe = {
        xaxis: {
          title: `${this.$t(
            "pipe_parts.details.height_profile.xaxis_name"
          )} (${this.$units.getAbbr("m")})
              `,
          showline: true,
        },
        yaxis: {
          title: `${this.$t(
            "pipe_parts.details.height_profile.yaxis_name"
          )} (${this.$units.getAbbr("m")})`,
          showline: true,
          range: [0, 1],
        },
        margin: {
          t: 30,
          b: 70,
          l: 70,
          r: 50,
        },
      };
      if (this.selectedInspectionStats) {
        dupe.yaxis["range"] = this.chartHeightRange;
      }

      if (this.visibleParts && this.visibleParts.length) {
        const visibleDistance = this.visibleParts.map((pp) => pp.distance);
        const dMax = Math.max(...visibleDistance);
        const dMin = Math.min(...visibleDistance);
        dupe.xaxis["range"] = [dMin, dMax];
      }

      return dupe;
    },
  },
};
</script>
