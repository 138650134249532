<template>
  <v-select
    :label="$t('pipe_parts.filters.materials')"
    :model-value="filters.selectedMaterials"
    @update:modelValue="(value) => setFilters({ selectedMaterials: value })"
    :items="items"
    multiple
    variant="underlined"
  />
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: {
    items: Array,
  },
  computed: {
    ...mapState("pipeParts", ["filters"]),
  },
  methods: {
    ...mapMutations("pipeParts", ["setFilters"]),
  },
};
</script>
