<template>
  <div class="widgets-list-container" ref="container">
    <WidgetCard
      v-for="widget in visibleWidgets"
      v-show="!widgetsState[widget.key].minimized"
      :key="widget.key"
      :x="widgetsState[widget.key].x"
      :y="widgetsState[widget.key].y"
      :w="widgetsState[widget.key].width"
      :h="widgetsState[widget.key].height"
      :parentW="containerWidth"
      :parentH="containerHeight"
      :active="widget.key === this.activeWidget"
      @drag="(newPosition) => handleWidgetDrag(widget, newPosition)"
      @resize="(newSize) => handleWidgetResize(widget, newSize)"
      @minimize="setWidgetMinimized(widget.key, true)"
      @mousedown.stop="activeWidget = widget.key"
      v-bind="widget.displayConfig || {}"
    >
      <template v-slot:title-prepend>
        <slot :name="`${widget.key}-title-prepend`" />
      </template>

      <template v-slot:title-text>
        <slot :name="`${widget.key}-title-text`">
          {{ widget.title }}
        </slot>
      </template>

      <template v-slot:content>
        <slot :name="`${widget.key}-content`" />
      </template>
    </WidgetCard>

    <WidgetsBar
      v-if="minimizedWidgetTabs.length > 0"
      :tabs="minimizedWidgetTabs"
      :tabsConfig="widgetsBarConfig"
      @maximize="(key) => setWidgetMinimized(key, false)"
    />
  </div>
</template>

<script>
import { ref } from "vue";

import { WidgetCard } from "./WidgetCard";
import WidgetsBar from "./WidgetsBar.vue";

import { useElementSize } from "@vueuse/core";
import { throttle } from "@/utils/throttle";

export default {
  name: "WidgetsList",

  components: { WidgetCard, WidgetsBar },

  props: {
    widgets: {
      type: Object,
      required: true,
    },

    widgetsBarConfig: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const container = ref(null);
    const { width: containerWidth, height: containerHeight } =
      useElementSize(container);

    return {
      containerWidth,
      containerHeight,
      container,
    };
  },

  data() {
    const widgetsState = this.getInitialWidgetsState();
    const throttledHandleResize = throttle(this.handleContainerResize, {
      delay: 30,
    });
    const resizeObserver = new ResizeObserver(throttledHandleResize);

    return {
      widgetsState,
      resizeObserver,
      activeWidget: null,
    };
  },

  methods: {
    getInitialWidgetsState() {
      const widgetsState = {};

      for (const [key, config] of Object.entries(this.widgets)) {
        const widgetState = {
          x: 0,
          y: 0,
          width: config.initialWidth,
          height: config.initialHeight,
          minimized: config.initiallyMinimized,
        };

        widgetsState[key] = widgetState;
      }

      return widgetsState;
    },

    moveWidgetsInsideBounds(listContainerEntry) {
      const containerWidth = listContainerEntry.borderBoxSize[0].inlineSize;
      const containerHeight = listContainerEntry.borderBoxSize[0].blockSize;

      for (const widget of this.widgetsList) {
        const widgetState = this.widgetsState[widget.key];

        const shouldMoveWidgetHorizontally =
          widgetState.x + widgetState.width > containerWidth;
        const shouldMoveWidgetVertically =
          widgetState.y + widgetState.height > containerHeight;

        if (shouldMoveWidgetHorizontally) {
          const difference =
            containerWidth - (widgetState.x + widgetState.width + 10);
          widgetState.x = widgetState.x + difference;
        }

        if (shouldMoveWidgetVertically) {
          const difference =
            containerHeight - (widgetState.y + widgetState.height + 10);
          widgetState.y = widgetState.y + difference;
        }
      }
    },

    moveWidgetsToInitialPositions() {
      const initialPositions = this.initialWidgetPositions;

      if (!initialPositions) {
        return;
      }

      const moveWidgetsToInitialPositionByAxis = (axis) => {
        for (const widget of this.widgetsList) {
          this.widgetsState[widget.key][axis] =
            initialPositions[widget.key][axis];
        }
      };

      moveWidgetsToInitialPositionByAxis("x");

      this.$nextTick().then(() => moveWidgetsToInitialPositionByAxis("y"));
    },

    handleContainerResize(resizeObserverEntries) {
      const listContainerEntry = resizeObserverEntries.at(0);

      this.moveWidgetsInsideBounds(listContainerEntry);
    },

    handleWidgetDrag(widget, { left: x, top: y }) {
      this.widgetsState[widget.key].x = x;
      this.widgetsState[widget.key].y = y;
    },

    handleWidgetResize(widget, { width, height }) {
      this.widgetsState[widget.key].width = width;
      this.widgetsState[widget.key].height = height;
    },

    setWidgetMinimized(widgetKey, isMinimized) {
      this.widgetsState[widgetKey].minimized = isMinimized;
    },

    resetActiveWidget() {
      this.activeWidget = null;
    },
  },

  computed: {
    widgetsList() {
      return Object.values(this.widgets);
    },

    initialWidgetPositions() {
      const container = this.container;

      if (!container) {
        return null;
      }

      const initialWidgetPositions = {};

      for (const widget of this.widgetsList) {
        const initialX = widget.getInitialX(container, this.widgets);
        const initialY = widget.getInitialY(container, this.widgets);

        initialWidgetPositions[widget.key] = {
          x: initialX,
          y: initialY,
        };
      }

      return initialWidgetPositions;
    },

    minimizedWidgetTabs() {
      const widgetsState = this.widgetsState;

      const minimizedWidgets = this.widgetsList.filter(
        (widget) =>
          widgetsState[widget.key].minimized && !this.widgets[widget.key].hidden
      );

      const minizedTabs = minimizedWidgets.map((widget) => ({
        key: widget.key,
        title: widget.title,
      }));

      return minizedTabs;
    },

    visibleWidgets() {
      return this.widgetsList.filter((widget) => !widget.hidden);
    },
  },

  mounted() {
    this.resizeObserver.observe(this.container);
    this.moveWidgetsToInitialPositions();
    document.addEventListener("mousedown", this.resetActiveWidget);
  },

  beforeUnmount() {
    this.resizeObserver.disconnect();
    document.removeEventListener("mousedown", this.resetActiveWidget);
  },
};
</script>

<style scoped lang="scss">
.widgets-list-container {
  position: absolute;
  inset: 0;
  overflow: none;

  pointer-events: none;

  & > * {
    pointer-events: all;
  }
}
</style>
