<template>
  <v-menu
    :model-value="show"
    @update:modelValue="(value) => $emit('update:show', value)"
    :close-on-content-click="false"
    location="bottom"
  >
    <template v-slot:activator="{ props }">
      <v-badge
        dot
        :color="hasActiveFilters ? '#d9534f' : '#ffffff00'"
        :offset-x="6"
      >
        <v-btn
          v-bind="props"
          variant="text"
          density="compact"
          append-icon="mdi-tune"
          >{{ $t("pipe_parts.filters.title") }}
        </v-btn>
      </v-badge>
    </template>
    <v-card class="filter-content" :width="300" @mousedown.stop>
      <v-card-text>
        <SafetyFactorMainContributorSelector
          :items="uncertaintiesOptions"
          hide-details
        />
        <SafetyFactorSlider class="slider">
          <template #title>
            <p>{{ $t("safety_factor.filters.safety_factor") }}</p>
          </template>
        </SafetyFactorSlider>
        <SafetyFactorLowerSlider class="slider">
          <template #title>
            <p>{{ $t("safety_factor.filters.safety_factor_lower") }}</p>
          </template>
        </SafetyFactorLowerSlider>
        <SafetyFactorUpperSlider class="slider">
          <template #title>
            <p>{{ $t("safety_factor.filters.safety_factor_upper") }}</p>
          </template>
        </SafetyFactorUpperSlider>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { mapGetters } from "vuex";

import {
  SafetyFactorLowerSlider,
  SafetyFactorUpperSlider,
  SafetyFactorMainContributorSelector,
  SafetyFactorSlider,
} from "@/pages/safety-factor/views/SafetyFactor/components";

export default {
  name: "FilterPopupMenu",

  emits: ["update:show"],

  props: {
    show: Boolean,
    uncertaintiesOptions: Array,
  },

  components: {
    SafetyFactorMainContributorSelector,
    SafetyFactorSlider,
    SafetyFactorLowerSlider,
    SafetyFactorUpperSlider,
  },

  computed: {
    ...mapGetters("safetyFactor", ["hasActiveFilters"]),
  },
};
</script>

<style lang="scss" scoped>
.slider {
  width: 258px;
  padding: 8px 0px 10px;

  p {
    font-size: 1rem;
    margin-bottom: 6px;
  }
}
</style>
