<template>
  <!-- FIXME looks like it's better to get getInspectionStats and two loadings from  store, but not from props -->
  <PipelinesMapmode
    v-if="isMapMode"
    :pipelines="groupPipelines"
    :isLoadingPipelines="isLoadingPipelines"
    :isLoadingStats="isLoadingStats"
    :onSelectPipelineId="onSelectPipelineId"
    :getInspectionStats="getInspectionStats"
  />
  <Pipelines
    v-else
    :pipelines="groupPipelines"
    :isLoadingPipelines="isLoadingPipelines"
    :isLoadingStats="isLoadingStats"
    :onSelectPipelineId="onSelectPipelineId"
  />
</template>

<script>
// @ is an alias to /src
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { userCommentsAPI } from "@/features/comments/api";

import { Pipelines, PipelinesMapmode } from "./views";

export default {
  components: { PipelinesMapmode, Pipelines },
  data() {
    return {
      requests: new Map(),
    };
  },

  computed: {
    ...mapGetters("pipelines", ["selectedPipeline"]),
    ...mapState("pipelines", [
      "groupPipelines",
      "isLoadingStats",
      "isLoadingPipelines",
      "selectedInspectionStats",
      "selectedInspectionId",
    ]),
    ...mapState("map", ["isMapMode"]),
    ...mapState("auth", ["selectedGroup"]),
  },

  methods: {
    ...mapMutations("pipelines", [
      "setSelectedPipelineId",
      "setPipelineElevation",
      "setComments",
      "setIsLoadingStats",
      "setSelectedInspectionId",
      "setSelectedInspectionStats",
    ]),
    ...mapActions("pipelines", [
      "getElevation",
      "getPipeComments",
      "getInspectionStats",
      "cancelRequests",
    ]),
    ...mapActions(["resetFilters"]),

    onSelectPipelineId(pipelineId) {
      this.setSelectedPipelineId(pipelineId);
    },
  },

  watch: {
    // TODO remove it to auth store (watcher) when will be Pinia
    selectedGroup() {
      this.cancelRequests();
      this.setIsLoadingStats(false);
      userCommentsAPI.abortAll();
    },

    selectedPipeline(pipeline) {
      this.resetFilters();
      this.setSelectedInspectionId(pipeline?.inspections[0].id);
      if (pipeline) {
        this.getPipeComments(pipeline.id);
      }
    },

    selectedInspectionId(newId) {
      this.cancelRequests();
      if (newId) {
        this.getInspectionStats();
      }
    },
  },
};
</script>
