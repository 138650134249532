export const pipelinesState = {
  isLoadingPipelines: false,
  isLoadingStats: false,
  groupPipelines: [],
  selectedPipelineId: null,
  pipelineElevation: [],
  comments: [],
  selectedInspectionId: null,
  selectedInspectionStats: null,
};
