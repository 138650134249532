import { auth0 } from "@/config/auth0";
import { getMultipleScannersDetails } from "@/features/pipescanner/api";
import { useToast } from "vue-toast-notification";
import ExportXlsx from "@/utils/ExportXlsx";
import { saveAs } from "file-saver";
import { dayjs } from "@/config/dayjs";
import { ScannerType } from "@/pages/scanner-control/config";

const requests = new Map();
const toast = useToast();

export const scannerControlActions = {
  async fetchPipeScanners({ rootState, dispatch }) {
    const requestsToFetch = [];

    if (rootState.pipeScanners.pipeScanners[ScannerType.Results].length === 0) {
      const fetchResultsScanners = dispatch(
        "pipeScanners/fetchResultsScanners",
        null,
        { root: true }
      );
      requestsToFetch.push(fetchResultsScanners);
    }

    if (
      rootState.pipeScanners.pipeScanners[ScannerType.InfoPending].length === 0
    ) {
      const fetchInfoScanners = dispatch(
        "pipeScanners/fetchInfoScanners",
        null,
        { root: true }
      );
      requestsToFetch.push(fetchInfoScanners);
    }

    if (
      rootState.pipeScanners.pipeScanners[ScannerType.AnalysisPending]
        .length === 0
    ) {
      const fetchAnalysisPendingScanners = dispatch(
        "pipeScanners/fetchAnalysisPendingScanners",
        null,
        {
          root: true,
        }
      );
      requestsToFetch.push(fetchAnalysisPendingScanners);
    }

    await Promise.all(requestsToFetch);
    dispatch("initFilters");
  },

  toggleAllResultsScanners({ commit, getters }) {
    if (getters.isAllResultsScannersSelected) {
      commit("clearSelectedResultScannerIds");
      return;
    }

    commit("setSelectedResultScannerIds", getters.filteredResultsScannersIds);
  },

  toggleResultsScannerSelection({ commit, state }, id) {
    if (state.selectedResultScannerIds.includes(id)) {
      commit("removeSelectedResultScannerId", id);
    } else {
      commit("addSelectedResultScannerId", id);
    }
  },

  // TODO: Maybe move this out as a separate helper
  async fetchScannersDetails({ state, rootState }) {
    try {
      const token = await auth0.getAccessTokenSilently();
      const controller = new AbortController();

      requests.set("multipleScannersDetails", controller);

      const inspection = await getMultipleScannersDetails(
        token,
        rootState.auth.selectedGroup,
        state.selectedResultScannerIds,
        controller
      );

      return inspection;
    } catch (error) {
      if (error.name !== "CanceledError") {
        console.error(error);
        toast.error(`Reading inspections - ${error.message}`, {
          position: "top-right",
        });
      }

      throw new Error("fetchScannersDetails");
    }
  },

  async exportResultsScanners({ commit, rootState, dispatch, state }) {
    try {
      commit("setIsAwaitingInspectionExport", true);

      const inspections = await dispatch("fetchScannersDetails");

      const settings = {
        ...state.exportSettings,
        language: rootState.settings.locale,
      };

      const excelExport = new ExportXlsx(
        inspections,
        0.1,
        15.0,
        settings,
        rootState.auth.selectedGroup,
        rootState.settings.units
      );

      const export_wb = await excelExport.generate_export_prom();
      const buffer = await export_wb.writeToBuffer();
      const file = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(file, "Export-" + dayjs().toISOString() + ".xlsx");
    } catch (error) {
      if (error.message !== "fetchScannersDetails") {
        console.error(error);
        toast.error(`Excel export - ${error.message}`, {
          position: "top-right",
        });
      }
    } finally {
      commit("setIsAwaitingInspectionExport", false);
    }
  },

  cancelAllRequests() {
    for (const request of requests.values()) {
      request.abort();
    }
  },

  initFilters({ getters, commit }) {
    commit("setFilters", { diameterRange: getters.minMaxDiameters });
  },

  toggleActiveScannerId({ commit, state }, id) {
    if (state.activeScannerIds[state.selectedScannerType] === id) {
      commit("setActiveScannerIds", { [state.selectedScannerType]: null });
      return;
    }

    commit("setActiveScannerIds", { [state.selectedScannerType]: id });
  },
};
