<template>
  <div>
    <ContainedModal v-model="open" scrollable width="40%">
      <v-card>
        <v-card-title>
          <span>{{ $t("comments_modal.title") }}</span>

          <div>
            <v-btn
              variant="tonal"
              size="small"
              :color="tab === 'text' ? '#24e3c6' : 'white'"
              prepend-icon="mdi-text"
              @click="tab = 'text'"
            >
              {{ $t("common.text") }}
            </v-btn>
            <v-btn
              v-if="isAuthor"
              variant="tonal"
              size="small"
              :color="tab === 'location' ? '#24e3c6' : 'white'"
              prepend-icon="mdi-map-marker"
              @click="tab = 'location'"
            >
              {{ $t("location.title") }}
            </v-btn>
            <v-btn
              variant="tonal"
              size="small"
              :color="tab === 'files' ? '#24e3c6' : 'white'"
              prepend-icon="mdi-attachment"
              @click="tab = 'files'"
            >
              {{ $t("common.files") }}
            </v-btn>
            <v-icon class="close-icon" icon="mdi-close" @click="closeModal" />
          </div>
        </v-card-title>
        <v-window v-model="tab">
          <v-window-item value="text">
            <v-card-text style="min-height: 200px">
              <QuillEditor
                v-if="isAuthor"
                class="acq-quill-editor"
                theme="snow"
                content-type="html"
                v-model:content="form.comment"
                style="height: 300px"
              />
              <QuillEditor
                v-if="!isAuthor"
                class="acq-quill-editor"
                theme=""
                content-type="html"
                :content="form.comment"
                readOnly
                style="height: 300px"
              />
            </v-card-text>
          </v-window-item>
          <v-window-item value="location">
            <v-card-text>
              <LocationUpdate
                v-model:latitude="form.geom.coordinates[1]"
                v-model:longitude="form.geom.coordinates[0]"
              />
            </v-card-text>
          </v-window-item>
          <v-window-item
            value="files"
            style="height: 500px; overflow-y: auto; margin-bottom: 1rem"
          >
            <FilesTab
              ref="refFiles"
              :commentId="form.id"
              :isAuthor="isAuthor"
              :isNewComment="isNewComment"
              :postComment="postComment"
            />
          </v-window-item>
        </v-window>

        <div
          style="
            display: flex;
            justify-content: space-between;
            padding: 0 1.5rem 1rem;
          "
        >
          <v-btn variant="outlined" @click="closeModal">
            {{ $t("common.cancel") }}
          </v-btn>

          <div v-if="isAuthor">
            <v-btn
              v-if="isAuthor && !isNewComment"
              style="margin-right: 0.7rem"
              variant="outlined"
              color="red"
              @click="showRemovingDialog = true"
            >
              {{ $t("common.delete") }}
            </v-btn>
            <v-btn
              :loading="isSendingComment"
              style="background: #181f33; color: white"
              @click="onSaveClick"
            >
              {{ $t("common.save") }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </ContainedModal>

    <ContainedModal v-model="showRemovingDialog" width="auto">
      <v-card class="sub-modal">
        <v-card-text>
          <p>
            <v-icon icon="mdi-alert" color="red" size="x-large"></v-icon>
          </p>
          {{ $t("comments_modal.removing_dialog.content") }}
        </v-card-text>
        <div class="dialog-actions">
          <v-btn
            variant="outlined"
            size="large"
            @click="showRemovingDialog = false"
          >
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn
            variant="flat"
            size="large"
            color="red"
            :loading="isDeleting"
            @click="deleteComment"
          >
            {{ $t("common.delete") }}
          </v-btn>
        </div>
      </v-card>
    </ContainedModal>

    <ContainedModal v-model="showAwaitingFilesDialog" max-width="800">
      <v-card class="sub-modal">
        <v-card-text>
          <p>
            <v-icon icon="mdi-alert" color="red" size="x-large"></v-icon>
          </p>
          {{ $t("comments_modal.awaiting_files_dialog.content") }}
        </v-card-text>
        <div class="dialog-actions">
          <v-btn
            variant="outlined"
            size="large"
            @click="showAwaitingFilesDialog = false"
          >
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn
            size="large"
            :loading="isSendingComment"
            style="background: #181f33; color: white"
            @click="saveComment"
          >
            {{ $t("comments_modal.awaiting_files_dialog.save_anyway") }}
          </v-btn>
        </div>
      </v-card>
    </ContainedModal>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { v4 as uuidv4 } from "uuid";
import jwt_decode from "jwt-decode";

import FilesTab from "./FilesTab.vue";
import { QuillEditor } from "@vueup/vue-quill";
import { LocationUpdate } from "@/components";
import ContainedModal from "@/components/ContainedModal.vue";

import { userCommentsAPI } from "@/features/comments/api";

export default {
  name: "CommentEditModal",

  components: { LocationUpdate, FilesTab, QuillEditor, ContainedModal },

  data() {
    return {
      open: false,
      isAuthor: true,
      isNewComment: true,
      tab: "text",
      isSendingComment: false,
      isDeleting: false,
      form: {
        id: undefined,
        pipeline_id: undefined,
        user_id: undefined,
        geom: { type: "Point", coordinates: [0, 0] },
        comment: "",
      },

      showRemovingDialog: false,
      showAwaitingFilesDialog: false,
    };
  },

  computed: {
    ...mapState("auth", ["selectedGroup"]),
    ...mapState("pipelines", ["selectedPipelineId"]),
  },

  methods: {
    ...mapMutations("pipelines", ["updateComment", "removeComment"]),

    openModal() {
      this.open = true;
    },

    closeModal() {
      this.open = false;
    },

    async editComment(lngLat, edited) {
      const userId = await this.getUserId();

      if (edited) {
        this.isNewComment = false;
        this.isAuthor = userId === edited.user_id;

        Object.keys(this.form).forEach((key) => {
          if (edited.hasOwnProperty(key)) {
            this.form[key] = edited[key];
          }
        });
      } else {
        this.isNewComment = true;
        this.isAuthor = true;

        this.form = {
          id: uuidv4(),
          pipeline_id: this.selectedPipelineId,
          user_id: userId,
          geom: { type: "Point", coordinates: lngLat },
          comment: "",
        };
      }

      this.tab = "text";
      this.openModal();
    },

    async getUserId() {
      const token = await this.$auth0.getAccessTokenSilently();
      const payload = jwt_decode(token);
      return payload?.sub;
    },

    onSaveClick() {
      if (this.$refs.refFiles?.hasAwaitingFiles) {
        this.showAwaitingFilesDialog = true;
      } else {
        this.saveComment();
      }
    },

    async saveComment() {
      try {
        this.isSendingComment = true;
        this.showAwaitingFilesDialog = false;

        await this.postComment();
        this.isSendingComment = false;
        this.closeModal();
      } catch {
        this.isSendingComment = false;
      }
    },

    async postComment() {
      try {
        const comment = await userCommentsAPI.create(
          this.form,
          this.selectedGroup
        );
        this.updateComment(comment);
        this.isNewComment = false;
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.log(error);
          this.$toast.error(`Post user comment - ${error.message}`, {
            position: "top-right",
          });
          throw new Error();
        }
      }
    },

    async deleteComment() {
      try {
        this.isDeleting = true;
        const response = await userCommentsAPI.remove(
          this.form.id,
          this.selectedGroup
        );
        if (response.status === 200) {
          this.removeComment(this.form.id);
        }
        this.showRemovingDialog = false;
        this.closeModal();
      } catch (error) {
        console.log(error);
        this.$toast.error(`Deleting comment - ${error.message}`, {
          position: "top-right",
        });
      } finally {
        this.isDeleting = false;
      }
    },
  },
};
</script>

<style>
.acq-quill-editor.ql-container {
  font-size: 1.2rem !important;
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.v-card {
  .v-card-title {
    color: $cyan-main;
    background: $black-primary;
    font-size: 1.1rem;

    display: flex;
    justify-content: space-between;

    .v-btn {
      margin-right: 8px;
    }

    .close-icon {
      color: white;
      &:hover {
        color: $cyan-main;
      }
    }
  }

  &.sub-modal {
    padding: 1rem 2rem 1.5rem;
    border-radius: 1rem !important;

    .v-card-text {
      font-size: 1.2rem !important;
      text-align: center;
      .mdi-alert {
        margin-bottom: 1rem;
      }
    }

    .dialog-actions {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
    }
  }
}
</style>
