<template>
  <div class="filter-tab-wrap">
    <div style="display: flex">
      <div style="width: 30%">
        <v-card :elevation="4" style="padding-bottom: 0">
          <v-select
            v-model="currentFileName"
            :items="usedFilesOptions"
            label="File"
            variant="underlined"
          ></v-select>
        </v-card>
        <v-card :elevation="4" style="padding: 1rem">
          <h3>{{ $t("analyser.filter.outlier_filter") }}</h3>
          <v-text-field
            :model-value="currentFileSettings.upperOffset"
            @update:modelValue="
              (value) => setSettingValue('upperOffset', value)
            "
            :label="$t('analyser.filter.upper_offset') + '*'"
            variant="underlined"
            type="number"
            class="px-3 mt-2"
            :loading="isLoadingFilters"
          ></v-text-field>
          <v-text-field
            :model-value="currentFileSettings.lowerOffset"
            @update:modelValue="
              (value) => setSettingValue('lowerOffset', value)
            "
            :label="$t('analyser.filter.lower_offset') + '*'"
            variant="underlined"
            type="number"
            class="px-3"
            :loading="isLoadingFilters"
          ></v-text-field>
          <v-text-field
            :model-value="currentFileSettings.upperTH"
            @update:modelValue="(value) => setSettingValue('upperTH', value)"
            :label="$t('analyser.filter.upper_threshold') + '*'"
            variant="underlined"
            type="number"
            class="px-3"
            :loading="isLoadingFilters"
          ></v-text-field>
          <v-text-field
            :model-value="currentFileSettings.lowerTH"
            @update:modelValue="(value) => setSettingValue('lowerTH', value)"
            :label="$t('analyser.filter.lower_threshold') + '*'"
            variant="underlined"
            type="number"
            class="px-3"
            :loading="isLoadingFilters"
          ></v-text-field>
          <v-btn
            rounded="lg"
            color="deep-orange"
            @click="applyFilter"
            :disabled="isLoadingFilters || !filters"
            :loading="isLoadingWT"
            >{{ $t("analyser.filter.apply_filter") }}</v-btn
          >
        </v-card>
      </div>

      <v-card :elevation="4" style="margin-left: 1.5rem; flex-grow: 1">
        <FilterWTChart
          :isLoading="isLoadingFilters"
          :currentFile="currentFile"
        />
      </v-card>
    </div>

    <div v-if="!isLoadingFilters" style="display: flex; margin-top: 2rem">
      <v-card :elevation="4" style="margin-bottom: 0; width: 50%">
        <FilterDistanceChart :currentFile="currentFile" />
      </v-card>

      <v-card :elevation="4" style="margin-left: 1.5rem; width: 50%">
        <FilterRollChart :currentFile="currentFile" />
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterWTChart from "./FilterWTChart.vue";
import FilterDistanceChart from "./FilterDistanceChart.vue";
import FilterRollChart from "./FilterRollChart.vue";
import { getFilterWT, postFilterWT } from "@/features/analyser/api";

export default {
  name: "FilterTab",
  components: {
    FilterWTChart,
    FilterDistanceChart,
    FilterRollChart,
  },
  props: {
    usedFilesOptions: Array,
  },
  data() {
    return {
      currentFileName: null,
      filters: null,
      isLoadingFilters: false,
      isLoadingWT: false,
    };
  },

  mounted() {
    this.getFilters();
    this.currentFileName = this.usedFilesOptions[0];
  },

  computed: {
    ...mapState("auth", ["selectedGroup"]),

    currentFile() {
      if (!this.filters || !this.filters.hasOwnProperty(this.currentFileName)) {
        return undefined;
      }
      return this.filters[this.currentFileName];
    },

    currentFileSettings() {
      if (!this.currentFile) {
        return {
          upperOffset: null,
          lowerOffset: null,
          upperTH: null,
          lowerTH: null,
        };
      }
      return this.currentFile.settings;
    },

    inspectionId() {
      return this.$route.params.id;
    },
  },

  methods: {
    async getFilters() {
      try {
        this.filters = null;
        this.isLoadingFilters = true;

        const token = await this.$auth0.getAccessTokenSilently();
        this.filters = await getFilterWT(
          token,
          this.inspectionId,
          this.selectedGroup
        );
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Read filters - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.isLoadingFilters = false;
      }
    },

    async applyFilter() {
      try {
        this.isLoadingWT = true;
        const token = await this.$auth0.getAccessTokenSilently();
        const form = {
          ...this.currentFileSettings,
          inspectionFile: this.currentFileName,
        };
        const data = await postFilterWT(
          token,
          this.inspectionId,
          this.selectedGroup,
          form
        );
        this.filters[this.currentFileName].results = { ...data };
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Read filter WT - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.isLoadingWT = false;
      }
    },

    setSettingValue(field, value) {
      if (this.filters && this.currentFileName) {
        this.filters[this.currentFileName].settings[field] = +value;
      }
    },
  },

  watch: {
    usedFilesOptions(options) {
      this.currentFileName = options[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-tab-wrap {
  .v-card {
    padding: 0.5rem 1rem;
  }
}
</style>
