<template>
  <div id="mapmod-container">
    <ModifiedMapbox
      :zoom="7"
      :wmsLayers="wmsLayers"
      :filteredScannersMapData="filteredScannersMapData"
      @mb-moveend="onMoveend"
      @mb-load="centerMapToPipeScanners(currentFilteredScanners, tab)"
      ref="modifiedMapRef"
      hideBackToPipeButton
    >
      <template v-slot:append>
        <WidgetsList :widgets="widgets" :widgetsBarConfig="widgetsBarConfig">
          <template
            v-slot:[`${ScannerControlWidget.ScannersTable}-title-prepend`]
          >
            <FilterPopupMenu
              v-model:show="filtersShown"
              :hasActiveFilters="hasFilteredScanners"
            />
            <ScannersTabs />
          </template>
          <template v-slot:[`${ScannerControlWidget.ScannersTable}-content`]>
            <div
              v-if="isLoading[selectedScannerType]"
              class="h-100 d-flex align-center justify-center"
            >
              <v-progress-circular
                color="dark-blue"
                indeterminate
                :size="52"
                :width="5"
              ></v-progress-circular>
            </div>
            <template v-else>
              <ResultsScannersTable
                v-if="selectedScannerType === ScannerType.Results"
                @scanner-edit="(id) => openEditModel(id, ScannerType.Results)"
                @scanner-details="(id) => openDetailedModal(id)"
                @scanner-select="(scanner) => handleTableItemSelect(scanner)"
              />
              <AnalysisScannersTable
                v-if="selectedScannerType === ScannerType.AnalysisPending"
                @scanner-select="(scanner) => handleTableItemSelect(scanner)"
                @scanner-edit="
                  (id) => openEditModel(id, ScannerType.AnalysisPending)
                "
              />
              <InfoScannersTable
                v-if="selectedScannerType === ScannerType.InfoPending"
                @scanner-select="(scanner) => handleTableItemSelect(scanner)"
                @scanner-edit="
                  (id) => openEditModel(id, ScannerType.InfoPending)
                "
              />
            </template>
          </template>

          <template v-slot:[`${ScannerControlWidget.WMSWidget}-content`]>
            <WMSWidget v-model:wmsLayers="wmsLayers" />
          </template>

          <template v-slot:[`${ScannerControlWidget.WMSLegend}-content`]>
            <WMSLegend :wmsLayers="wmsLayers" :currentZoom="currentZoom" />
          </template>
        </WidgetsList>

        <ScannerLoaders class="scanner-loaders" :visible="hasLoadingScanners" />
      </template>
    </ModifiedMapbox>
  </div>
</template>

<script>
import { computed } from "vue";
import { mapState, mapGetters } from "vuex";

import {
  ResultsScannersTable,
  AnalysisScannersTable,
  InfoScannersTable,
  ScannersTabs,
} from "@/pages/scanner-control/components";
import { ModifiedMapbox } from "@/components";
import { WMSLegend, WMSWidget } from "@/features/wms/components";
import { ScannerLoaders, FilterPopupMenu } from "./components";
import { WidgetsList } from "@/components/map-componets/widgets";

import { getScannersClusterSourceAndLayers } from "@/features/pipescanner/helpers";
import { fitMapToCoordinates } from "@/utils/mapHelpers";

import { ScannerType } from "@/pages/scanner-control/config";
import { ScannerControlWidget } from "./config";

export default {
  name: "ScannerControlMapmode",

  components: {
    WMSLegend,
    WMSWidget,
    ModifiedMapbox,
    ScannerLoaders,
    ResultsScannersTable,
    AnalysisScannersTable,
    InfoScannersTable,
    ScannersTabs,
    WidgetsList,
    FilterPopupMenu,
  },

  provide() {
    return {
      parentW: computed(() => this.parentW),
      parentH: computed(() => this.parentH),
    };
  },

  setup() {
    return {
      ScannerType,
      ScannerControlWidget,
    };
  },

  data() {
    return {
      currentZoom: null,
      wmsLayers: [],
      filtersShown: false,
    };
  },

  computed: {
    ...mapState("map", ["mapStyle"]),

    ...mapState("pipeScanners", ["isLoading", "pipeScanners"]),

    ...mapState("scannerControl", ["selectedScannerType"]),

    ...mapGetters("scannerControl", [
      "currentFilteredScanners",
      "hasFilteredScanners",
    ]),

    widgets() {
      return {
        [ScannerControlWidget.ScannersTable]: {
          key: ScannerControlWidget.ScannersTable,
          title: this.$t("pipe_scanner.pipescanners"),
          initialWidth: 840,
          initialHeight: 450,
          getInitialX: (container, widgets) =>
            container.offsetWidth -
            (widgets[ScannerControlWidget.ScannersTable].initialWidth + 5),
          getInitialY: (container, widgets) =>
            container.offsetHeight -
            (widgets[ScannerControlWidget.ScannersTable].initialHeight + 5),
          initiallyMinimized: true,
        },

        [ScannerControlWidget.WMSWidget]: {
          key: ScannerControlWidget.WMSWidget,
          title: this.$t("safety_factor.widgets.color"),
          initialWidth: 350,
          initialHeight: "auto",
          getInitialX: (container, widgets) =>
            (container.offsetWidth -
              widgets[ScannerControlWidget.WMSWidget].initialWidth) /
            2,
          getInitialY: () => 5,
          initiallyMinimized: false,

          displayConfig: {
            isResizable: false,
          },
        },

        [ScannerControlWidget.WMSLegend]: {
          key: ScannerControlWidget.WMSLegend,
          title: this.$t("wms_layers.layers"),
          initialWidth: 250,
          initialHeight: 365,
          getInitialX: () => 5,
          getInitialY: () => 180,
          initiallyMinimized: false,
          hidden: this.wmsLayers.length === 0,
        },
      };
    },

    widgetsBarConfig() {
      return {
        [ScannerControlWidget.ScannersTable]: {
          showFiltersIndicator: this.hasFilteredScanners,
        },
      };
    },

    map() {
      return this.$refs.modifiedMapRef.map;
    },

    filteredScannersMapData() {
      return getScannersClusterSourceAndLayers(
        this.currentFilteredScanners,
        this.selectedScannerType
      );
    },

    hasLoadingScanners() {
      return Object.values(this.isLoading).some((value) => value);
    },
  },

  methods: {
    onMoveend({ target: map }) {
      this.currentZoom = map.getZoom();
    },

    openEditModel(id, status) {
      this.$refs.modifiedMapRef.openEditModel(id, status);
    },

    openDetailedModal(id) {
      this.$refs.modifiedMapRef.openDetailedModal(id);
    },

    handleTableItemSelect(scanner) {
      this.map.jumpTo({
        center: [scanner.longitude, scanner.latitude],
        zoom: 17,
      });
    },

    getPipeScannerCoordinates(pipeScanners) {
      const coordinates = pipeScanners.map((pipeScanner) => [
        pipeScanner.longitude,
        pipeScanner.latitude,
      ]);

      return coordinates;
    },

    centerMapToPipeScanners(pipeScanners) {
      const coordinates = this.getPipeScannerCoordinates(pipeScanners);

      if (coordinates.length === 0) {
        return;
      }

      fitMapToCoordinates(this.map, coordinates, { padding: 200 });
    },
  },

  watch: {
    pipeScanners: {
      handler() {
        this.centerMapToPipeScanners(this.currentFilteredScanners);
      },

      deep: true,
    },

    selectedScannerType() {
      if (this.activeScanner) {
        this.centerMapToPipeScanners([this.activeScanner]);
        return;
      }

      this.centerMapToPipeScanners(this.currentFilteredScanners);
    },
  },
};
</script>

<style lang="scss" scoped>
#mapmod-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

  .scanner-marker {
    cursor: pointer;
  }

  .zIndex {
    z-index: 5 !important;
  }

  .inactive {
    opacity: 0.8;
  }

  .filter-indicator {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #d9534f;
  }

  .scanner-loaders {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 500px;
  }
}
</style>
