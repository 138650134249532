<template>
  <DragResize
    :isDraggable="isDraggable"
    @dragging="$emit('drag', $event)"
    @resizing="$emit('resize', $event)"
    parentLimitation
    v-bind="$attrs"
  >
    <VCard
      class="widget-card"
      :class="{
        'widget-card-active': this.active,
      }"
    >
      <VCardTitle
        v-if="showTitle"
        class="card-title"
        :class="{ 'card-title-draggable': isDraggable }"
      >
        <div class="title-text font-weight-thin">
          <slot name="title-text" />
        </div>
        <div class="title-prepend">
          <slot name="title-prepend" />
          <MinimizeWidgetButton
            v-if="isMinimizable"
            @click="$emit('minimize', $event)"
          />
        </div>
      </VCardTitle>

      <VCardText class="card-text">
        <slot name="content" />
      </VCardText>
    </VCard>
  </DragResize>
</template>

<script>
import DragResize from "./DragResize.vue";
import MinimizeWidgetButton from "./MinimizeWidgetButton.vue";

export default {
  name: "WidgetCard",

  emits: ["drag", "minimize", "resize"],

  components: {
    DragResize,
    MinimizeWidgetButton,
  },

  props: {
    heightFitContent: Boolean,

    isDraggable: {
      type: Boolean,
      default: true,
    },

    isMinimizable: {
      type: Boolean,
      default: true,
    },

    showTitle: {
      type: Boolean,
      default: true,
    },

    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
.widget-card {
  height: 100%;
  opacity: 0.8;
  z-index: auto;
  display: flex;
  flex-direction: column;
}

.widget-card-active {
  opacity: 1;
  z-index: 100;
}

.card-title {
  display: flex;
  justify-content: space-between;
  user-select: none;
  text-wrap: wrap;
}

.card-title-draggable {
  cursor: move;
}

.title-prepend {
  display: flex;
  align-items: center;
  color: white;
}

.card-text {
  padding: 0 !important;
  overflow-x: hidden;
  flex-grow: 1;
}
</style>
