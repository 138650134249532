<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/valid-v-slot -->
<template>
  <div class="container" rounded="lg">
    <div class="back-to-block">
      <v-btn
        density="compact"
        icon="mdi-arrow-left-circle-outline"
        variant="text"
        @click="$router.push('/scanner-control')"
      ></v-btn>
      <span class="id">Inspection Analyser : {{ inspectionId }}</span>
    </div>
    <v-stepper alt-labels v-model="currentStep">
      <template v-slot:default="{ prev, next }">
        <v-stepper-header>
          <template v-for="step in 4" :key="step">
            <v-stepper-item
              :color="getStepperItemColor(step)"
              :value="step"
              :complete="currentStep > step || isPublished"
              :icon="currentStep === step ? 'mdi-pencil' : undefined"
              >{{ steps[step - 1] }}</v-stepper-item
            >
            <v-divider v-if="step < 4"></v-divider>
          </template>
        </v-stepper-header>

        <div
          :style="{
            display: 'flex',
            padding: '0.8rem 1.5rem 0.6rem 1rem',
            justifyContent: 'space-between',
          }"
        >
          <v-btn
            :disabled="currentStep === 1"
            @click="prev"
            color="#1c243d"
            style="color: white"
            >{{ $t("analyser.controls.previous") }}</v-btn
          >
          <v-btn
            v-if="currentStep !== 4"
            @click="next"
            color="#1c243d"
            :disabled="!completedSteps.has(currentStep)"
            >{{ $t("analyser.controls.next") }}</v-btn
          >
          <v-btn
            v-else
            @click="publish"
            rounded="lg"
            :loading="isPublishing"
            color="deep-orange"
            >{{ $t("analyser.controls.publish") }}</v-btn
          >
        </div>

        <v-stepper-window>
          <v-stepper-window-item :value="1">
            <div class="p1" style="display: flex">
              <FileSelectTable
                :files="inspectionFiles"
                :onCheck="setFileUse"
                :isLoading="isLoadingFiles"
                :onSave="saveUsedFiles"
              />
              <BScanChart
                style="flex-grow: 1; margin-left: 1rem"
                :bScans="bScans"
                :progressBcans="progressBcans"
                :allFilesOptions="allFilesOptions"
              />
            </div>
          </v-stepper-window-item>

          <v-stepper-window-item :value="2">
            <div class="p1">
              <PeakDetectTab
                :bScans="bScans"
                :progressBcans="progressBcans"
                :files="inspectionFiles"
                :usedFilesOptions="usedFilesOptions"
                @step-complete="completedSteps.add(2)"
              /></div
          ></v-stepper-window-item>
          <v-stepper-window-item :value="3">
            <div class="p1">
              <MappingTab @step-complete="completedSteps.add(3)" /></div
          ></v-stepper-window-item>
          <v-stepper-window-item :value="4">
            <div class="p1">
              <FilterTab :usedFilesOptions="usedFilesOptions" /></div
          ></v-stepper-window-item>
        </v-stepper-window>
      </template>
    </v-stepper>
    <ReturnDialog ref="refReturnDialog" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  filterInspectionData,
  getInspectionFiles,
  getInspectionBScans,
  postUsedFiles,
  publishAnalyser,
} from "@/features/analyser/api";

import {
  FileSelectTable,
  BScanChart,
  FilterTab,
  PeakDetectTab,
  MappingTab,
} from "./AnalyserTabs";
import ReturnDialog from "./ReturnDialog.vue";

export default {
  name: "ScannerAnalyser",
  components: {
    FileSelectTable,
    BScanChart,
    PeakDetectTab,
    MappingTab,
    FilterTab,
    ReturnDialog,
  },
  setup() {
    const steps = ["Administration", "Peak Detect", "Mapping", "Filter"];
    return { steps };
  },
  data() {
    return {
      currentStep: 1,
      completedSteps: new Set(),
      requests: new Map(),
      isLoadingFiles: true,
      inspectionFiles: [],
      progressBcans: {
        isLoading: true,
        percent: 0,
      },
      bScans: null,
      isPublishing: false,
      isPublished: false,
    };
  },
  mounted() {
    this.initInspection();
  },

  computed: {
    ...mapState("auth", ["selectedGroup"]),

    inspectionId() {
      return this.$route.params.id;
    },

    allFilesOptions() {
      if (!this.inspectionFiles) {
        return [];
      }
      return this.inspectionFiles.map(({ name }) => name);
    },

    usedFilesOptions() {
      if (!this.inspectionFiles) {
        return [];
      }
      return this.inspectionFiles
        .filter(({ use }) => use)
        .map(({ name }) => name);
    },
  },

  methods: {
    onDownloadProgress(progressEvent) {
      if (progressEvent) {
        this.progressBcans.percent = (progressEvent.progress * 100).toFixed();
      }
    },

    async initInspection() {
      try {
        const token = await this.$auth0.getAccessTokenSilently();
        const controller = new AbortController();
        this.requests.set("filterInspectionData", controller);
        const response = await filterInspectionData(
          token,
          this.inspectionId,
          this.selectedGroup,
          controller
        );
        if (response.status === 200) {
          this.fetchFiles();
          this.fetchBScans();
        }
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Filter inspection data - ${error.message}`, {
            position: "top-right",
          });
        }
      }
    },

    async fetchFiles() {
      try {
        this.inspectionFiles = [];
        this.isLoadingFiles = true;

        const token = await this.$auth0.getAccessTokenSilently();
        const controller = new AbortController();
        this.requests.set("inspectionFiles", controller);
        const data = await getInspectionFiles(
          token,
          this.inspectionId,
          this.selectedGroup,
          controller
        );
        this.inspectionFiles = data;
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Read files - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.isLoadingFiles = false;
      }
    },

    async fetchBScans() {
      try {
        this.progressBcans.isLoading = true;
        this.bScans = null;

        const token = await this.$auth0.getAccessTokenSilently();
        const controller = new AbortController();
        this.requests.set("inspectionBScans", controller);
        const data = await getInspectionBScans(
          token,
          this.inspectionId,
          this.selectedGroup,
          this.onDownloadProgress,
          controller
        );
        this.bScans = data;
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Read b_scans - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.progressBcans.isLoading = false;
      }
    },

    async saveUsedFiles() {
      try {
        const token = await this.$auth0.getAccessTokenSilently();

        const controller = new AbortController();
        this.requests.set("saveUsedFiles", controller);
        const response = await postUsedFiles(
          token,
          this.inspectionId,
          this.selectedGroup,
          this.inspectionFiles,
          controller
        );
        if (response.status === 200) {
          this.$toast.success(`Used files are saved`, {
            position: "top-right",
          });
          this.completedSteps.add(1);
        }
      } catch (error) {
        this.$toast.error(`Save used files - ${error.message}`, {
          position: "top-right",
        });
      }
    },

    async publish() {
      try {
        this.isPublishing = true;

        const token = await this.$auth0.getAccessTokenSilently();
        const { message } = await publishAnalyser(
          token,
          this.inspectionId,
          this.selectedGroup
        );
        this.$toast.success(message, {
          position: "top-right",
        });
        this.isPublished = true;

        this.$refs.refReturnDialog.open();
      } catch (error) {
        if (error.name !== "CanceledError") {
          console.error(error);
          this.$toast.error(`Publish - ${error.message}`, {
            position: "top-right",
          });
        }
      } finally {
        this.isPublishing = false;
      }
    },

    setFileUse(index) {
      this.inspectionFiles[index].use = !this.inspectionFiles[index].use;
    },

    getStepperItemColor(step) {
      if (this.currentStep > step || this.isPublished) {
        return "green";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-block {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  .v-btn:hover {
    color: #24e3c6;
  }
  .id {
    font-weight: 500;
    font-size: 1.1rem;
    margin-left: 0.5rem;
  }
}

.v-card {
  margin-bottom: 0 !important;
}
.v-slide-group {
  box-shadow: none !important;
}

.v-stepper-window {
  margin-top: 0 !important;
}

.p1 {
  padding: 5px;
}
</style>
