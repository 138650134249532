<template>
  <v-radio-group
    density="comfortable"
    color="#0a9e87"
    v-model="mapStyleModel"
    hide-details
    inline
  >
    <v-radio
      label="Default"
      density="compact"
      :value="mapStyles.default"
    ></v-radio>
    <v-radio
      label="Satellite"
      density="compact"
      :value="mapStyles.satellite"
    ></v-radio>
  </v-radio-group>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { STYLE_MAP, STYLE_SATELLITE } from "@/constants/mapBox";

export default {
  name: "MapStyleSelect",
  setup() {
    return {
      mapStyles: { default: STYLE_MAP, satellite: STYLE_SATELLITE },
    };
  },
  methods: {
    ...mapMutations("map", ["setMapStyle"]),
  },
  computed: {
    ...mapState("map", ["mapStyle"]),

    mapStyleModel: {
      get() {
        return this.mapStyle;
      },
      set(style) {
        this.setMapStyle(style);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-radio-group {
  position: absolute;
  z-index: 1 !important;
  background-color: white;
  padding-right: 10px;
  border-radius: 0 8px 8px 0;
  margin-top: 7px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
</style>
