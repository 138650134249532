<template>
  <div class="slider">
    <RangeSlider
      :title="$t('anomalies.filters.distance')"
      :model-value="anomalyFilters.selectedDistance"
      @update:modelValue="(value) => setFilters({ selectedDistance: value })"
      :min="minMaxDistance[0]"
      :max="minMaxDistance[1]"
      :step="0.01"
    />
  </div>
</template>

<script>
import RangeSlider from "@/components/RangeSlider";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState("anomalies", ["anomalyFilters"]),
    ...mapGetters("anomalies", ["minMaxDistance"]),
  },

  methods: {
    ...mapMutations("anomalies", ["setFilters"]),
  },

  components: {
    RangeSlider,
  },
};
</script>

<style scoped lang="scss">
.slider {
  width: 25%;
  text-align: center;
}
</style>
