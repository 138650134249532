<template>
  <div v-if="selectedPipeline" class="container">
    <v-card rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("joints.table_name") }}
        <i>
          {{ inspectionTitle }}
        </i>
      </v-card-title>
      <v-card-text :class="[isLoading ? '' : 'pt-6px']">
        <div v-if="isLoading" class="text-center">
          <v-progress-circular
            color="dark-blue"
            indeterminate
            :size="52"
            :width="5"
          ></v-progress-circular>
        </div>
        <div v-else>
          <div class="filters-containers">
            <div class="filter-selectors">
              <JointsTypesSelector hide-details />
              <JointsMaterialsSelector hide-details />
            </div>
            <div class="filter-sliders">
              <JointsVerticalAngleSlider style="padding-right: 0.7rem" />
              <JointsHorizontalAngleSlider style="padding-right: 1rem" />
              <JointsGapWidthMeanSlider />
              <JointsGapWidthMaxSlider />
            </div>
          </div>
          <JointsTable />
        </div>
      </v-card-text>
    </v-card>

    <v-card v-if="!isLoading" rounded="lg">
      <v-card-title class="font-weight-thin">
        {{ $t("joints.details.title") }}
      </v-card-title>
      <v-card-text>
        <p class="text-h6">{{ $t("joints.details.map") }}</p>
        <JointsMap
          :jointsMapData="jointsMapData"
          :gradientOptions="gradientOptions"
          :gradientColor="gradientColor"
        />
        <p class="text-h6">
          {{ $t("joints.details.height_profile.title") }}
        </p>
        <JointsHeightChart />
        <JointsHistogramChart />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

import {
  JointsTable,
  JointsHeightChart,
  JointsHistogramChart,
} from "@/pages/joints/components";
import {
  JointsGapWidthMaxSlider,
  JointsGapWidthMeanSlider,
  JointsHorizontalAngleSlider,
  JointsMap,
  JointsMaterialsSelector,
  JointsTypesSelector,
  JointsVerticalAngleSlider,
} from "./components";

export default {
  name: "JointsRegular",

  props: {
    gradientOptions: Array,

    gradientColor: Array,
    jointsMapData: Array,
  },

  components: {
    JointsTable,
    JointsMap,
    JointsHeightChart,
    JointsHistogramChart,
    JointsVerticalAngleSlider,
    JointsHorizontalAngleSlider,
    JointsGapWidthMeanSlider,
    JointsGapWidthMaxSlider,
    JointsTypesSelector,
    JointsMaterialsSelector,
  },

  computed: {
    ...mapState("pipelines", [
      "selectedInspectionStats",
      "selectedInspectionId",
    ]),

    ...mapGetters("pipelines", ["selectedPipeline"]),

    ...mapState("joints", [
      "isLoading",
      "jointsSelector",
      "selectedGradientMode",
      "selectedLegend",
    ]),

    ...mapGetters("joints", ["jointTypes", "minMaxGapMean", "minMaxGapMax"]),

    inspectionTitle() {
      if (!this.selectedPipeline) return "";
      const inspectionName = this.selectedPipeline.inspections.find(
        (ins) => ins.id === this.selectedInspectionId
      )?.project_reference;
      return `- ${this.selectedPipeline.client_pipeline_id} : ${this.selectedPipeline.pipeline_name} - ${inspectionName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.pt-6px {
  padding-top: 6px !important;
}

.filters-containers {
  .filter-selectors {
    display: flex;
    .v-select {
      padding: 0 1rem;
      width: 50%;
    }
  }
  .filter-sliders {
    display: flex;
    text-align: center;
    margin: 1rem 0;
  }
}
</style>
