<template>
  <ResizableVirtualTable
    :headers="displayedHeaders"
    :items="filteredJoints"
    :is-item-active="isJointActive"
    @click:row="(joint) => $emit('click:row', joint.id)"
    showSelect
  >
    <template v-slot:[`header.select`]>
      <TableButton
        :disabled="checkedLength === 0"
        @click="jointsSelector.clear()"
        variant="text"
        icon="mdi-close-box"
      />
    </template>

    <template v-slot:[`item.select`]="{ item: joint }">
      <VCheckboxBtn
        :modelValue="jointsSelector.has(joint.id)"
        :disabled="
          shouldDisableCheckbox(joint.id, filteredJoints, jointsSelector)
        "
        @update:modelValue="jointsSelector.setByCtrl(joint.id)"
        @click.stop
      />
    </template>
  </ResizableVirtualTable>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { ResizableVirtualTable } from "@/components";
import { TableButton } from "@/components/table-components/controls";

import { shouldDisableCheckbox } from "@/features/dig-up/helpers";
import { getDegradationColorJoints } from "@/colorLegends";

import { MODES_WITHOUT_ADDITIONAL_COLUMNS } from "./config";

export default {
  name: "JointsTableRegular",

  components: { ResizableVirtualTable, TableButton },

  props: {
    checkedLength: Number,
    isJointActive: Function,
  },

  emits: ["click:row"],

  setup() {
    return {
      shouldDisableCheckbox,
    };
  },

  data() {
    return {
      headers: [
        {
          title: this.$t("joints.table_titles.joint_id"),
          key: "joint_id",
          value: "joint_id",
        },
        {
          title: this.$t("joints.table_titles.joint_type"),
          key: "joint_type",
          value: "joint_type",
        },
        {
          title: this.$t("joints.table_titles.material"),
          key: "material",
          value: "material",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("joints", ["filteredJoints"]),
    ...mapState("joints", ["jointsSelector", "selectedGradientMode"]),

    additionalHeaders() {
      if (
        MODES_WITHOUT_ADDITIONAL_COLUMNS.includes(this.selectedGradientMode)
      ) {
        return [];
      }

      return [
        {
          title: this.$t(`joints.table_titles.${this.selectedGradientMode}`),
          key: "selected_gradient",
          value: (joint) => this.getSelectedGradientCellValue(joint),
        },
      ];
    },

    displayedHeaders() {
      return this.headers.concat(this.additionalHeaders);
    },
  },

  methods: {
    getSelectedGradientCellValue(joint) {
      switch (this.selectedGradientMode) {
        case "angle_vertical":
        case "angle_horizontal":
          return Number(joint[this.selectedGradientMode]).toFixed(2);

        case "degradation_level":
          return getDegradationColorJoints(this.$t)[
            joint[this.selectedGradientMode]
          ].text;
        case "gap_width_max":
        case "gap_width_mean":
          return Number(joint[this.selectedGradientMode]).toFixed(2);

        default:
          return null;
      }
    },
  },
};
</script>
