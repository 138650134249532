<template>
  <div class="colors-legend-toolbar-wrapper">
    <VBtn
      variant="text"
      density="compact"
      icon="mdi-information-variant-circle-outline"
      @click="$emit('click:info')"
      @mousedown.stop
    />
  </div>
</template>

<script>
export default {
  name: "ColorsLegendToolbar",

  emits: {
    "click:info": null,
  },
};
</script>

<style scoped lang="scss">
.colors-legend-toolbar-wrapper {
  display: flex;
  align-items: center;
}
</style>
